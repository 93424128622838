@font-face {
  font-family: 'BioClinica_Icons_master';
  src:  url('../fonts/icons/BioClinica_Icons_master.eot');
  src:  url('../fonts/icons/BioClinica_Icons_master.ttf') format('truetype'),
    url('../fonts/icons/BioClinica_Icons_master.woff') format('woff'),
    url('../fonts/icons/BioClinica_Icons_master.svg#BioClinica_Icons_master') format('svg');
  font-weight: normal;
  font-style: normal;
}

.lnr {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'BioClinica_Icons_master' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-home:before {
  content: "\e600";
}
.icon-home3:before {
  content: "\e602";
}
.icon-city:before {
  content: "\e60b";
}
.icon-apartment:before {
  content: "\e60c";
}
.icon-pencil:before {
  content: "\e60d";
}
.icon-pencil2:before {
  content: "\e60e";
}
.icon-eraser:before {
  content: "\e611";
}
.icon-pencil4:before {
  content: "\e612";
}
.icon-pencil5:before {
  content: "\e613";
}
.icon-brush2:before {
  content: "\e61e";
}
.icon-eye-dropper:before {
  content: "\e625";
}
.icon-magnet:before {
  content: "\e648";
}
.icon-bottle:before {
  content: "\e632";
}
.icon-sun-small:before {
  content: "\e646";
}
.icon-sun:before {
  content: "\e647";
}
.icon-cloud:before {
  content: "\e64a";
}
.icon-cloud-upload:before {
  content: "\e64b";
}
.icon-cloud-download:before {
  content: "\e64c";
}
.icon-cloud-lightning:before {
  content: "\e654";
}
.icon-cloud-sync:before {
  content: "\e655";
}
.icon-cloud-lock:before {
  content: "\e656";
}
.icon-cloud-gear:before {
  content: "\e657";
}
.icon-cloud-alert:before {
  content: "\e658";
}
.icon-cloud-check:before {
  content: "\e659";
}
.icon-cloud-cross:before {
  content: "\e65a";
}
.icon-cloud-crossed:before {
  content: "\e65b";
}
.icon-cloud-database:before {
  content: "\e65c";
}
.icon-database:before {
  content: "\e65d";
}
.icon-database-add:before {
  content: "\e65e";
}
.icon-database-remove:before {
  content: "\e65f";
}
.icon-database-lock:before {
  content: "\e660";
}
.icon-database-refresh:before {
  content: "\e661";
}
.icon-database-check:before {
  content: "\e662";
}
.icon-database-history:before {
  content: "\e663";
}
.icon-database-upload:before {
  content: "\e664";
}
.icon-database-download:before {
  content: "\e665";
}
.icon-server:before {
  content: "\e666";
}
.icon-shield:before {
  content: "\e667";
}
.icon-shield-check:before {
  content: "\e668";
}
.icon-shield-alert:before {
  content: "\e669";
}
.icon-shield-cross:before {
  content: "\e66a";
}
.icon-lock:before {
  content: "\e66b";
}
.icon-rotation-lock:before {
  content: "\e66c";
}
.icon-unlock:before {
  content: "\e66d";
}
.icon-key:before {
  content: "\e66e";
}
.icon-key-hole:before {
  content: "\e66f";
}
.icon-toggle-off:before {
  content: "\e670";
}
.icon-toggle-on:before {
  content: "\e671";
}
.icon-cog:before {
  content: "\e672";
}
.icon-cog2:before {
  content: "\e673";
}
.icon-wrench:before {
  content: "\e674";
}
.icon-screwdriver:before {
  content: "\e675";
}
.icon-hammer-wrench:before {
  content: "\e676";
}
.icon-recycle:before {
  content: "\e67f";
}
.icon-trash:before {
  content: "\e680";
}
.icon-trash2:before {
  content: "\e681";
}
.icon-trash3:before {
  content: "\e682";
}
.icon-heart:before {
  content: "\e68c";
}
.icon-star:before {
  content: "\e68d";
}
.icon-star-half:before {
  content: "\e68e";
}
.icon-star-empty:before {
  content: "\e68f";
}
.icon-flag:before {
  content: "\e690";
}
.icon-flag2:before {
  content: "\e691";
}
.icon-flag3:before {
  content: "\e692";
}
.icon-at-sign:before {
  content: "\e695";
}
.icon-envelope:before {
  content: "\e696";
}
.icon-envelope-open:before {
  content: "\e697";
}
.icon-paperclip:before {
  content: "\e698";
}
.icon-reply:before {
  content: "\e69a";
}
.icon-reply-all:before {
  content: "\e69b";
}
.icon-inbox:before {
  content: "\e69c";
}
.icon-inbox2:before {
  content: "\e69d";
}
.icon-outbox:before {
  content: "\e69e";
}
.icon-box:before {
  content: "\e69f";
}
.icon-archive:before {
  content: "\e6a0";
}
.icon-archive2:before {
  content: "\e6a1";
}
.icon-drawers:before {
  content: "\e6a2";
}
.icon-drawers2:before {
  content: "\e6a3";
}
.icon-drawers3:before {
  content: "\e6a4";
}
.icon-eye:before {
  content: "\e6a5";
}
.icon-eye-crossed:before {
  content: "\e6a6";
}
.icon-eye-plus:before {
  content: "\e6a7";
}
.icon-eye-minus:before {
  content: "\e6a8";
}
.icon-hdd:before {
  content: "\e6ab";
}
.icon-hdd-down:before {
  content: "\e6ac";
}
.icon-hdd-up:before {
  content: "\e6ad";
}
.icon-floppy-disk:before {
  content: "\e6ae";
}
.icon-disc:before {
  content: "\e6af";
}
.icon-printer:before {
  content: "\e6b1";
}
.icon-shredder:before {
  content: "\e6b2";
}
.icon-file-empty:before {
  content: "\e6b3";
}
.icon-file-add:before {
  content: "\e6b4";
}
.icon-file-check:before {
  content: "\e6b5";
}
.icon-file-lock:before {
  content: "\e6b6";
}
.icon-files:before {
  content: "\e6b7";
}
.icon-copy:before {
  content: "\e6b8";
}
.icon-compare:before {
  content: "\e6b9";
}
.icon-folder:before {
  content: "\e6ba";
}
.icon-folder-search:before {
  content: "\e6bb";
}
.icon-folder-plus:before {
  content: "\e6bc";
}
.icon-folder-minus:before {
  content: "\e6bd";
}
.icon-folder-download:before {
  content: "\e6be";
}
.icon-folder-upload:before {
  content: "\e6bf";
}
.icon-folder-star:before {
  content: "\e6c0";
}
.icon-folder-heart:before {
  content: "\e6c1";
}
.icon-folder-user:before {
  content: "\e6c2";
}
.icon-folder-shared:before {
  content: "\e6c3";
}
.icon-scissors:before {
  content: "\e6c7";
}
.icon-paste:before {
  content: "\e6c8";
}
.icon-clipboard-empty:before {
  content: "\e6c9";
}
.icon-clipboard-pencil:before {
  content: "\e6ca";
}
.icon-clipboard-text:before {
  content: "\e6cb";
}
.icon-clipboard-check:before {
  content: "\e6cc";
}
.icon-clipboard-down:before {
  content: "\e6cd";
}
.icon-clipboard-left:before {
  content: "\e6ce";
}
.icon-clipboard-alert:before {
  content: "\e6cf";
}
.icon-clipboard-user:before {
  content: "\e6d0";
}
.icon-register:before {
  content: "\e6d1";
}
.icon-enter:before {
  content: "\e6d2";
}
.icon-exit:before {
  content: "\e6d3";
}
.icon-document:before {
  content: "\e6d8";
}
.icon-document2:before {
  content: "\e6d9";
}
.icon-graduation-hat:before {
  content: "\e6da";
}
.icon-license:before {
  content: "\e6db";
}
.icon-license2:before {
  content: "\e6dc";
}
.icon-medal-empty:before {
  content: "\e6dd";
}
.icon-medal-first:before {
  content: "\e6de";
}
.icon-medal-second:before {
  content: "\e6df";
}
.icon-medal-third:before {
  content: "\e6e0";
}
.icon-trophy2:before {
  content: "\e6e3";
}
.icon-repeat:before {
  content: "\e6ee";
}
.icon-equalizer:before {
  content: "\e6f2";
}
.icon-presentation:before {
  content: "\e6f6";
}
.icon-play:before {
  content: "\e6f7";
}
.icon-film-play:before {
  content: "\e6f8";
}
.icon-clapboard-play:before {
  content: "\e6f9";
}
.icon-camera:before {
  content: "\e6ff";
}
.icon-camera-crossed:before {
  content: "\e700";
}
.icon-camera-play:before {
  content: "\e701";
}
.icon-camera2:before {
  content: "\e704";
}
.icon-picture:before {
  content: "\e70e";
}
.icon-picture2:before {
  content: "\e70f";
}
.icon-picture3:before {
  content: "\e710";
}
.icon-pictures:before {
  content: "\e711";
}
.icon-book2:before {
  content: "\e714";
}
.icon-bookmark:before {
  content: "\e715";
}
.icon-bookmark2:before {
  content: "\e716";
}
.icon-label:before {
  content: "\e717";
}
.icon-library:before {
  content: "\e718";
}
.icon-library2:before {
  content: "\e719";
}
.icon-contacts:before {
  content: "\e71a";
}
.icon-profile:before {
  content: "\e71b";
}
.icon-portrait:before {
  content: "\e71c";
}
.icon-portrait2:before {
  content: "\e71d";
}
.icon-user:before {
  content: "\e71e";
}
.icon-user-plus:before {
  content: "\e71f";
}
.icon-user-minus:before {
  content: "\e720";
}
.icon-user-lock:before {
  content: "\e721";
}
.icon-users:before {
  content: "\e722";
}
.icon-users2:before {
  content: "\e723";
}
.icon-users-plus:before {
  content: "\e724";
}
.icon-users-minus:before {
  content: "\e725";
}
.icon-group-work:before {
  content: "\e726";
}
.icon-woman:before {
  content: "\e727";
}
.icon-man:before {
  content: "\e728";
}
.icon-walk:before {
  content: "\e72d";
}
.icon-hand-waving:before {
  content: "\e72e";
}
.icon-jump:before {
  content: "\e72f";
}
.icon-run:before {
  content: "\e730";
}
.icon-woman2:before {
  content: "\e731";
}
.icon-man2:before {
  content: "\e732";
}
.icon-man-woman:before {
  content: "\e733";
}
.icon-height:before {
  content: "\e734";
}
.icon-weight:before {
  content: "\e735";
}
.icon-bow-tie:before {
  content: "\e738";
}
.icon-tie:before {
  content: "\e739";
}
.icon-store:before {
  content: "\e744";
}
.icon-barcode:before {
  content: "\e747";
}
.icon-barcode2:before {
  content: "\e748";
}
.icon-barcode3:before {
  content: "\e749";
}
.icon-cart:before {
  content: "\e74d";
}
.icon-cart-empty:before {
  content: "\e74e";
}
.icon-cart-full:before {
  content: "\e74f";
}
.icon-cart-plus:before {
  content: "\e750";
}
.icon-cart-plus2:before {
  content: "\e751";
}
.icon-cart-add:before {
  content: "\e752";
}
.icon-cart-remove:before {
  content: "\e753";
}
.icon-cart-exchange:before {
  content: "\e754";
}
.icon-tag:before {
  content: "\e755";
}
.icon-tags:before {
  content: "\e756";
}
.icon-receipt:before {
  content: "\e757";
}
.icon-wallet:before {
  content: "\e758";
}
.icon-credit-card:before {
  content: "\e759";
}
.icon-cash-dollar:before {
  content: "\e75a";
}
.icon-cash-euro:before {
  content: "\e75b";
}
.icon-cash-pound:before {
  content: "\e75c";
}
.icon-cash-yen:before {
  content: "\e75d";
}
.icon-bag-dollar:before {
  content: "\e75e";
}
.icon-bag-euro:before {
  content: "\e75f";
}
.icon-bag-pound:before {
  content: "\e760";
}
.icon-bag-yen:before {
  content: "\e761";
}
.icon-coin-dollar:before {
  content: "\e762";
}
.icon-coin-euro:before {
  content: "\e763";
}
.icon-coin-pound:before {
  content: "\e764";
}
.icon-coin-yen:before {
  content: "\e765";
}
.icon-calculator:before {
  content: "\e766";
}
.icon-calculator2:before {
  content: "\e767";
}
.icon-vault:before {
  content: "\e769";
}
.icon-telephone:before {
  content: "\e76a";
}
.icon-phone-lock:before {
  content: "\e76b";
}
.icon-phone-wave:before {
  content: "\e76c";
}
.icon-phone-pause:before {
  content: "\e76d";
}
.icon-phone-outgoing:before {
  content: "\e76e";
}
.icon-phone-incoming:before {
  content: "\e76f";
}
.icon-phone-in-out:before {
  content: "\e770";
}
.icon-phone-error:before {
  content: "\e771";
}
.icon-phone-sip:before {
  content: "\e772";
}
.icon-phone-plus:before {
  content: "\e773";
}
.icon-phone-minus:before {
  content: "\e774";
}
.icon-voicemail:before {
  content: "\e775";
}
.icon-dial:before {
  content: "\e776";
}
.icon-telephone2:before {
  content: "\e777";
}
.icon-pushpin:before {
  content: "\e778";
}
.icon-pushpin2:before {
  content: "\e779";
}
.icon-map-marker:before {
  content: "\e77a";
}
.icon-map-marker-user:before {
  content: "\e77b";
}
.icon-map-marker-down:before {
  content: "\e77c";
}
.icon-map-marker-check:before {
  content: "\e77d";
}
.icon-map-marker-crossed:before {
  content: "\e77e";
}
.icon-radar:before {
  content: "\e77f";
}
.icon-compass2:before {
  content: "\e780";
}
.icon-map2:before {
  content: "\e782";
}
.icon-location:before {
  content: "\e783";
}
.icon-road-sign:before {
  content: "\e784";
}
.icon-calendar-empty:before {
  content: "\e785";
}
.icon-calendar-check:before {
  content: "\e786";
}
.icon-calendar-cross:before {
  content: "\e787";
}
.icon-calendar-31:before {
  content: "\e788";
}
.icon-calendar-full:before {
  content: "\e789";
}
.icon-calendar-insert:before {
  content: "\e78a";
}
.icon-calendar-text:before {
  content: "\e78b";
}
.icon-calendar-user:before {
  content: "\e78c";
}
.icon-mouse:before {
  content: "\e78d";
}
.icon-mouse-left:before {
  content: "\e78e";
}
.icon-mouse-right:before {
  content: "\e78f";
}
.icon-mouse-both:before {
  content: "\e790";
}
.icon-keyboard:before {
  content: "\e791";
}
.icon-keyboard-up:before {
  content: "\e792";
}
.icon-keyboard-down:before {
  content: "\e793";
}
.icon-delete:before {
  content: "\e794";
}
.icon-spell-check:before {
  content: "\e795";
}
.icon-screen:before {
  content: "\e798";
}
.icon-signal:before {
  content: "\e79a";
}
.icon-signal-lock:before {
  content: "\e79b";
}
.icon-signal-80:before {
  content: "\e79c";
}
.icon-signal-60:before {
  content: "\e79d";
}
.icon-signal-40:before {
  content: "\e79e";
}
.icon-signal-20:before {
  content: "\e79f";
}
.icon-signal-0:before {
  content: "\e7a0";
}
.icon-signal-blocked:before {
  content: "\e7a1";
}
.icon-sim:before {
  content: "\e7a2";
}
.icon-flash-memory:before {
  content: "\e7a3";
}
.icon-tablet2:before {
  content: "\e7ac";
}
.icon-laptop:before {
  content: "\e7ad";
}
.icon-laptop-phone:before {
  content: "\e7ae";
}
.icon-desktop:before {
  content: "\e7af";
}
.icon-launch:before {
  content: "\e7b0";
}
.icon-new-tab:before {
  content: "\e7b1";
}
.icon-window:before {
  content: "\e7b2";
}
.icon-power-switch:before {
  content: "\e7b8";
}
.icon-power:before {
  content: "\e7b9";
}
.icon-power-crossed:before {
  content: "\e7ba";
}
.icon-flash-auto:before {
  content: "\e7bb";
}
.icon-lamp:before {
  content: "\e7bc";
}
.icon-flashlight:before {
  content: "\e7bd";
}
.icon-lampshade:before {
  content: "\e7be";
}
.icon-cord:before {
  content: "\e7bf";
}
.icon-outlet:before {
  content: "\e7c0";
}
.icon-battery-power:before {
  content: "\e7c1";
}
.icon-battery-empty:before {
  content: "\e7c2";
}
.icon-battery-alert:before {
  content: "\e7c3";
}
.icon-battery-error:before {
  content: "\e7c4";
}
.icon-battery-low1:before {
  content: "\e7c5";
}
.icon-battery-low2:before {
  content: "\e7c6";
}
.icon-battery-low3:before {
  content: "\e7c7";
}
.icon-battery-mid1:before {
  content: "\e7c8";
}
.icon-battery-mid2:before {
  content: "\e7c9";
}
.icon-battery-mid3:before {
  content: "\e7ca";
}
.icon-battery-full:before {
  content: "\e7cb";
}
.icon-battery-charging:before {
  content: "\e7cc";
}
.icon-battery-charging2:before {
  content: "\e7cd";
}
.icon-battery-charging3:before {
  content: "\e7ce";
}
.icon-battery-charging4:before {
  content: "\e7cf";
}
.icon-battery-charging5:before {
  content: "\e7d0";
}
.icon-battery-charging6:before {
  content: "\e7d1";
}
.icon-battery-charging7:before {
  content: "\e7d2";
}
.icon-bubble:before {
  content: "\e7d6";
}
.icon-bubbles:before {
  content: "\e7d7";
}
.icon-bubble-dots:before {
  content: "\e7d8";
}
.icon-bubble-alert:before {
  content: "\e7d9";
}
.icon-bubble-question:before {
  content: "\e7da";
}
.icon-bubble-text:before {
  content: "\e7db";
}
.icon-bubble-pencil:before {
  content: "\e7dc";
}
.icon-bubble-picture:before {
  content: "\e7dd";
}
.icon-bubble-video:before {
  content: "\e7de";
}
.icon-bubble-user:before {
  content: "\e7df";
}
.icon-bubble-quote:before {
  content: "\e7e0";
}
.icon-bubble-heart:before {
  content: "\e7e1";
}
.icon-bubble-emoticon:before {
  content: "\e7e2";
}
.icon-bubble-attachment:before {
  content: "\e7e3";
}
.icon-phone-bubble:before {
  content: "\e7e4";
}
.icon-dna:before {
  content: "\e7e7";
}
.icon-heart-pulse:before {
  content: "\e7e8";
}
.icon-pulse:before {
  content: "\e7e9";
}
.icon-syringe:before {
  content: "\e7ea";
}
.icon-pills:before {
  content: "\e7eb";
}
.icon-first-aid:before {
  content: "\e7ec";
}
.icon-lifebuoy:before {
  content: "\e7ed";
}
.icon-bandage:before {
  content: "\e7ee";
}
.icon-bandages:before {
  content: "\e7ef";
}
.icon-thermometer:before {
  content: "\e7f0";
}
.icon-microscope:before {
  content: "\e7f1";
}
.icon-brain:before {
  content: "\e7f2";
}
.icon-beaker:before {
  content: "\e7f3";
}
.icon-construction:before {
  content: "\e7f6";
}
.icon-construction-cone:before {
  content: "\e7f7";
}
.icon-pie-chart:before {
  content: "\e7f8";
}
.icon-pie-chart2:before {
  content: "\e7f9";
}
.icon-graph:before {
  content: "\e7fa";
}
.icon-chart-growth:before {
  content: "\e7fb";
}
.icon-chart-bars:before {
  content: "\e7fc";
}
.icon-chart-settings:before {
  content: "\e7fd";
}
.icon-cake:before {
  content: "\e7fe";
}
.icon-gift:before {
  content: "\e7ff";
}
.icon-balloon:before {
  content: "\e800";
}
.icon-rank:before {
  content: "\e801";
}
.icon-rank2:before {
  content: "\e802";
}
.icon-rank3:before {
  content: "\e803";
}
.icon-diamond2:before {
  content: "\e807";
}
.icon-diamond3:before {
  content: "\e808";
}
.icon-dinner:before {
  content: "\e811";
}
.icon-dinner2:before {
  content: "\e812";
}
.icon-speed-slow:before {
  content: "\e834";
}
.icon-speed-medium:before {
  content: "\e835";
}
.icon-speed-fast:before {
  content: "\e836";
}
.icon-hammer2:before {
  content: "\e838";
}
.icon-balance:before {
  content: "\e839";
}
.icon-briefcase:before {
  content: "\e83a";
}
.icon-plane:before {
  content: "\e83d";
}
.icon-plane-crossed:before {
  content: "\e83e";
}
.icon-gas:before {
  content: "\e847";
}
.icon-ambulance:before {
  content: "\e856";
}
.icon-truck:before {
  content: "\e857";
}
.icon-cube:before {
  content: "\e874";
}
.icon-puzzle:before {
  content: "\e876";
}
.icon-wheelchair:before {
  content: "\e87a";
}
.icon-icons2:before {
  content: "\e880";
}
.icon-select:before {
  content: "\e881";
}
.icon-select2:before {
  content: "\e882";
}
.icon-site-map:before {
  content: "\e883";
}
.icon-earth:before {
  content: "\e884";
}
.icon-earth-lock:before {
  content: "\e885";
}
.icon-network:before {
  content: "\e886";
}
.icon-network-lock:before {
  content: "\e887";
}
.icon-alarm:before {
  content: "\e8aa";
}
.icon-alarm-add:before {
  content: "\e8ab";
}
.icon-alarm-snooze:before {
  content: "\e8ac";
}
.icon-alarm-ringing:before {
  content: "\e8ad";
}
.icon-bullhorn:before {
  content: "\e8ae";
}
.icon-hearing:before {
  content: "\e8af";
}
.icon-volume-high:before {
  content: "\e8b0";
}
.icon-volume-medium:before {
  content: "\e8b1";
}
.icon-volume-low:before {
  content: "\e8b2";
}
.icon-volume:before {
  content: "\e8b3";
}
.icon-mute:before {
  content: "\e8b4";
}
.icon-lan:before {
  content: "\e8b5";
}
.icon-lan2:before {
  content: "\e8b6";
}
.icon-wifi:before {
  content: "\e8b7";
}
.icon-wifi-lock:before {
  content: "\e8b8";
}
.icon-wifi-blocked:before {
  content: "\e8b9";
}
.icon-wifi-mid:before {
  content: "\e8ba";
}
.icon-wifi-low:before {
  content: "\e8bb";
}
.icon-wifi-low2:before {
  content: "\e8bc";
}
.icon-wifi-alert:before {
  content: "\e8bd";
}
.icon-wifi-alert-mid:before {
  content: "\e8be";
}
.icon-wifi-alert-low:before {
  content: "\e8bf";
}
.icon-wifi-alert-low2:before {
  content: "\e8c0";
}
.icon-stream:before {
  content: "\e8c1";
}
.icon-stream-check:before {
  content: "\e8c2";
}
.icon-stream-error:before {
  content: "\e8c3";
}
.icon-stream-alert:before {
  content: "\e8c4";
}
.icon-communication:before {
  content: "\e8c5";
}
.icon-communication-crossed:before {
  content: "\e8c6";
}
.icon-broadcast:before {
  content: "\e8c7";
}
.icon-antenna:before {
  content: "\e8c8";
}
.icon-mic:before {
  content: "\e8cb";
}
.icon-mic-mute:before {
  content: "\e8cc";
}
.icon-refresh:before {
  content: "\e8d3";
}
.icon-refresh2:before {
  content: "\e8d4";
}
.icon-undo:before {
  content: "\e8d5";
}
.icon-redo:before {
  content: "\e8d6";
}
.icon-jump2:before {
  content: "\e8d7";
}
.icon-undo2:before {
  content: "\e8d8";
}
.icon-redo2:before {
  content: "\e8d9";
}
.icon-sync:before {
  content: "\e8da";
}
.icon-repeat-one2:before {
  content: "\e8db";
}
.icon-sync-crossed:before {
  content: "\e8dc";
}
.icon-sync2:before {
  content: "\e8dd";
}
.icon-repeat-one3:before {
  content: "\e8de";
}
.icon-sync-crossed2:before {
  content: "\e8df";
}
.icon-return:before {
  content: "\e8e0";
}
.icon-return2:before {
  content: "\e8e1";
}
.icon-refund:before {
  content: "\e8e2";
}
.icon-history:before {
  content: "\e8e3";
}
.icon-history2:before {
  content: "\e8e4";
}
.icon-self-timer:before {
  content: "\e8e5";
}
.icon-clock:before {
  content: "\e8e6";
}
.icon-clock2:before {
  content: "\e8e7";
}
.icon-clock3:before {
  content: "\e8e8";
}
.icon-watch:before {
  content: "\e8e9";
}
.icon-alarm2:before {
  content: "\e8ea";
}
.icon-alarm-add2:before {
  content: "\e8eb";
}
.icon-alarm-remove:before {
  content: "\e8ec";
}
.icon-alarm-check:before {
  content: "\e8ed";
}
.icon-alarm-error:before {
  content: "\e8ee";
}
.icon-timer:before {
  content: "\e8ef";
}
.icon-timer-crossed:before {
  content: "\e8f0";
}
.icon-timer2:before {
  content: "\e8f1";
}
.icon-timer-crossed2:before {
  content: "\e8f2";
}
.icon-download:before {
  content: "\e8f3";
}
.icon-upload:before {
  content: "\e8f4";
}
.icon-download2:before {
  content: "\e8f5";
}
.icon-upload2:before {
  content: "\e8f6";
}
.icon-enter-up:before {
  content: "\e8f7";
}
.icon-enter-down:before {
  content: "\e8f8";
}
.icon-enter-left:before {
  content: "\e8f9";
}
.icon-enter-right:before {
  content: "\e8fa";
}
.icon-exit-up:before {
  content: "\e8fb";
}
.icon-exit-down:before {
  content: "\e8fc";
}
.icon-exit-left:before {
  content: "\e8fd";
}
.icon-exit-right:before {
  content: "\e8fe";
}
.icon-enter-up2:before {
  content: "\e8ff";
}
.icon-enter-down2:before {
  content: "\e900";
}
.icon-enter-vertical:before {
  content: "\e901";
}
.icon-enter-left2:before {
  content: "\e902";
}
.icon-enter-right2:before {
  content: "\e903";
}
.icon-enter-horizontal:before {
  content: "\e904";
}
.icon-exit-up2:before {
  content: "\e905";
}
.icon-exit-down2:before {
  content: "\e906";
}
.icon-exit-left2:before {
  content: "\e907";
}
.icon-exit-right2:before {
  content: "\e908";
}
.icon-cli:before {
  content: "\e909";
}
.icon-bug:before {
  content: "\e90a";
}
.icon-code:before {
  content: "\e90b";
}
.icon-file-code:before {
  content: "\e90c";
}
.icon-file-image:before {
  content: "\e90d";
}
.icon-file-zip:before {
  content: "\e90e";
}
.icon-file-audio:before {
  content: "\e90f";
}
.icon-file-video:before {
  content: "\e910";
}
.icon-file-preview:before {
  content: "\e911";
}
.icon-file-charts:before {
  content: "\e912";
}
.icon-file-stats:before {
  content: "\e913";
}
.icon-file-spreadsheet:before {
  content: "\e914";
}
.icon-link:before {
  content: "\e915";
}
.icon-unlink:before {
  content: "\e916";
}
.icon-link2:before {
  content: "\e917";
}
.icon-unlink2:before {
  content: "\e918";
}
.icon-thumbs-up:before {
  content: "\e919";
}
.icon-thumbs-down:before {
  content: "\e91a";
}
.icon-thumbs-up2:before {
  content: "\e91b";
}
.icon-thumbs-down2:before {
  content: "\e91c";
}
.icon-thumbs-up3:before {
  content: "\e91d";
}
.icon-thumbs-down3:before {
  content: "\e91e";
}
.icon-share:before {
  content: "\e91f";
}
.icon-share2:before {
  content: "\e920";
}
.icon-magnifier:before {
  content: "\e922";
}
.icon-file-search:before {
  content: "\e923";
}
.icon-find-replace:before {
  content: "\e924";
}
.icon-zoom-in:before {
  content: "\e925";
}
.icon-zoom-out:before {
  content: "\e926";
}
.icon-loupe:before {
  content: "\e927";
}
.icon-loupe-zoom-in:before {
  content: "\e928";
}
.icon-loupe-zoom-out:before {
  content: "\e929";
}
.icon-cross:before {
  content: "\e92a";
}
.icon-menu:before {
  content: "\e92b";
}
.icon-list:before {
  content: "\e92c";
}
.icon-list2:before {
  content: "\e92d";
}
.icon-list3:before {
  content: "\e92e";
}
.icon-menu2:before {
  content: "\e92f";
}
.icon-list4:before {
  content: "\e930";
}
.icon-menu3:before {
  content: "\e931";
}
.icon-exclamation:before {
  content: "\e932";
}
.icon-question:before {
  content: "\e933";
}
.icon-check:before {
  content: "\e934";
}
.icon-cross2:before {
  content: "\e935";
}
.icon-plus:before {
  content: "\e936";
}
.icon-minus:before {
  content: "\e937";
}
.icon-percent:before {
  content: "\e938";
}
.icon-chevron-up:before {
  content: "\e939";
}
.icon-chevron-down:before {
  content: "\e93a";
}
.icon-chevron-left:before {
  content: "\e93b";
}
.icon-chevron-right:before {
  content: "\e93c";
}
.icon-chevrons-expand-vertical:before {
  content: "\e93d";
}
.icon-chevrons-expand-horizontal:before {
  content: "\e93e";
}
.icon-chevrons-contract-vertical:before {
  content: "\e93f";
}
.icon-chevrons-contract-horizontal:before {
  content: "\e940";
}
.icon-arrow-up:before {
  content: "\e941";
}
.icon-arrow-down:before {
  content: "\e942";
}
.icon-arrow-left:before {
  content: "\e943";
}
.icon-arrow-right:before {
  content: "\e944";
}
.icon-arrow-up-right:before {
  content: "\e945";
}
.icon-arrows-merge:before {
  content: "\e946";
}
.icon-arrows-split:before {
  content: "\e947";
}
.icon-arrow-divert:before {
  content: "\e948";
}
.icon-arrow-return:before {
  content: "\e949";
}
.icon-expand:before {
  content: "\e94a";
}
.icon-contract:before {
  content: "\e94b";
}
.icon-expand2:before {
  content: "\e94c";
}
.icon-contract2:before {
  content: "\e94d";
}
.icon-move:before {
  content: "\e94e";
}
.icon-tab:before {
  content: "\e94f";
}
.icon-arrow-wave:before {
  content: "\e950";
}
.icon-expand3:before {
  content: "\e951";
}
.icon-expand4:before {
  content: "\e952";
}
.icon-contract3:before {
  content: "\e953";
}
.icon-notification:before {
  content: "\e954";
}
.icon-warning:before {
  content: "\e955";
}
.icon-notification-circle:before {
  content: "\e956";
}
.icon-checkmark-circle:before {
  content: "\e959";
}
.icon-cross-circle:before {
  content: "\e95a";
}
.icon-plus-circle:before {
  content: "\e95b";
}
.icon-circle-minus:before {
  content: "\e95c";
}
.icon-percent-circle:before {
  content: "\e95d";
}
.icon-arrow-up-circle:before {
  content: "\e95e";
}
.icon-arrow-down-circle:before {
  content: "\e95f";
}
.icon-arrow-left-circle:before {
  content: "\e960";
}
.icon-arrow-right-circle:before {
  content: "\e961";
}
.icon-chevron-up-circle:before {
  content: "\e962";
}
.icon-chevron-down-circle:before {
  content: "\e963";
}
.icon-chevron-left-circle:before {
  content: "\e964";
}
.icon-chevron-right-circle:before {
  content: "\e965";
}
.icon-backward-circle:before {
  content: "\e966";
}
.icon-first-circle:before {
  content: "\e967";
}
.icon-previous-circle:before {
  content: "\e968";
}
.icon-stop-circle:before {
  content: "\e969";
}
.icon-play-circle:before {
  content: "\e96a";
}
.icon-pause-circle:before {
  content: "\e96b";
}
.icon-next-circle:before {
  content: "\e96c";
}
.icon-last-circle:before {
  content: "\e96d";
}
.icon-forward-circle:before {
  content: "\e96e";
}
.icon-eject-circle:before {
  content: "\e96f";
}
.icon-crop:before {
  content: "\e970";
}
.icon-grid:before {
  content: "\e975";
}
.icon-grid-crossed:before {
  content: "\e976";
}
.icon-layers:before {
  content: "\e977";
}
.icon-layers-crossed:before {
  content: "\e978";
}
.icon-ruler:before {
  content: "\e97b";
}
.icon-funnel:before {
  content: "\e97c";
}
.icon-exclude:before {
  content: "\e986";
}
.icon-align-center-vertical:before {
  content: "\e987";
}
.icon-align-right:before {
  content: "\e988";
}
.icon-align-bottom:before {
  content: "\e989";
}
.icon-align-left:before {
  content: "\e98a";
}
.icon-align-center-horizontal:before {
  content: "\e98b";
}
.icon-align-top:before {
  content: "\e98c";
}
.icon-square:before {
  content: "\e98d";
}
.icon-plus-square:before {
  content: "\e98e";
}
.icon-minus-square:before {
  content: "\e98f";
}
.icon-percent-square:before {
  content: "\e990";
}
.icon-arrow-up-square:before {
  content: "\e991";
}
.icon-arrow-down-square:before {
  content: "\e992";
}
.icon-arrow-left-square:before {
  content: "\e993";
}
.icon-arrow-right-square:before {
  content: "\e994";
}
.icon-chevron-up-square:before {
  content: "\e995";
}
.icon-chevron-down-square:before {
  content: "\e996";
}
.icon-chevron-left-square:before {
  content: "\e997";
}
.icon-chevron-right-square:before {
  content: "\e998";
}
.icon-check-square:before {
  content: "\e999";
}
.icon-cross-square:before {
  content: "\e99a";
}
.icon-menu-square:before {
  content: "\e99b";
}
.icon-prohibited:before {
  content: "\e99c";
}
.icon-circle:before {
  content: "\e99d";
}
.icon-text-size:before {
  content: "\e9a2";
}
.icon-bold:before {
  content: "\e9a3";
}
.icon-italic:before {
  content: "\e9a4";
}
.icon-underline:before {
  content: "\e9a5";
}
.icon-highlight:before {
  content: "\e9a7";
}
.icon-text-align-left:before {
  content: "\e9a8";
}
.icon-text-align-center:before {
  content: "\e9a9";
}
.icon-text-align-right:before {
  content: "\e9aa";
}
.icon-text-align-justify:before {
  content: "\e9ab";
}
.icon-line-spacing:before {
  content: "\e9ac";
}
.icon-indent-increase:before {
  content: "\ebd5";
}
.icon-indent-decrease:before {
  content: "\ebd6";
}
.icon-text-wrap:before {
  content: "\e9af";
}
.icon-pilcrow:before {
  content: "\e9b0";
}
.icon-direction-ltr:before {
  content: "\e9b1";
}
.icon-direction-rtl:before {
  content: "\e9b2";
}
.icon-page-break:before {
  content: "\e9b3";
}
.icon-page-break2:before {
  content: "\e9b4";
}
.icon-sort-alpha-asc:before {
  content: "\e9b5";
}
.icon-sort-alpha-desc:before {
  content: "\e9b6";
}
.icon-sort-numeric-asc:before {
  content: "\e9b7";
}
.icon-sort-numeric-desc:before {
  content: "\e9b8";
}
.icon-sort-amount-asc:before {
  content: "\e9b9";
}
.icon-sort-amount-desc:before {
  content: "\e9ba";
}
.icon-sort-time-asc:before {
  content: "\e9bb";
}
.icon-sort-time-desc:before {
  content: "\e9bc";
}
.icon-pencil-line:before {
  content: "\e9be";
}
.icon-hand:before {
  content: "\e9bf";
}
.icon-pointer-up:before {
  content: "\e9c0";
}
.icon-pointer-right:before {
  content: "\e9c1";
}
.icon-pointer-down:before {
  content: "\e9c2";
}
.icon-pointer-left:before {
  content: "\e9c3";
}
.icon-finger-tap:before {
  content: "\e9c4";
}
.icon-fingers-tap:before {
  content: "\e9c5";
}
.icon-reminder:before {
  content: "\e9c6";
}
.icon-fingers-crossed:before {
  content: "\e9c7";
}
.icon-fingers-victory:before {
  content: "\e9c8";
}
.icon-gesture-zoom:before {
  content: "\e9c9";
}
.icon-gesture-pinch:before {
  content: "\e9ca";
}
.icon-fingers-scroll-horizontal:before {
  content: "\e9cb";
}
.icon-fingers-scroll-vertical:before {
  content: "\e9cc";
}
.icon-fingers-scroll-left:before {
  content: "\e9cd";
}
.icon-fingers-scroll-right:before {
  content: "\e9ce";
}
.icon-hand2:before {
  content: "\e9cf";
}
.icon-pointer-up2:before {
  content: "\e9d0";
}
.icon-pointer-right2:before {
  content: "\e9d1";
}
.icon-pointer-down2:before {
  content: "\e9d2";
}
.icon-pointer-left2:before {
  content: "\e9d3";
}
.icon-finger-tap2:before {
  content: "\e9d4";
}
.icon-fingers-tap2:before {
  content: "\e9d5";
}
.icon-reminder2:before {
  content: "\e9d6";
}
.icon-gesture-zoom2:before {
  content: "\e9d7";
}
.icon-gesture-pinch2:before {
  content: "\e9d8";
}
.icon-fingers-scroll-horizontal2:before {
  content: "\e9d9";
}
.icon-fingers-scroll-vertical2:before {
  content: "\e9da";
}
.icon-fingers-scroll-left2:before {
  content: "\e9db";
}
.icon-fingers-scroll-right2:before {
  content: "\e9dc";
}
.icon-fingers-scroll-vertical3:before {
  content: "\e9dd";
}
.icon-border-style:before {
  content: "\e9de";
}
.icon-border-all:before {
  content: "\e9df";
}
.icon-border-outer:before {
  content: "\e9e0";
}
.icon-border-inner:before {
  content: "\e9e1";
}
.icon-border-top:before {
  content: "\e9e2";
}
.icon-border-horizontal:before {
  content: "\e9e3";
}
.icon-border-bottom:before {
  content: "\e9e4";
}
.icon-border-left:before {
  content: "\e9e5";
}
.icon-border-vertical:before {
  content: "\e9e6";
}
.icon-border-right:before {
  content: "\e9e7";
}
.icon-border-none:before {
  content: "\e9e8";
}
.icon-ellipsis:before {
  content: "\e9e9";
}
.icon-mail-envelope:before {
  content: "\ea44";
}
.icon-mail-envelope2:before {
  content: "\ea45";
}
.icon-mail-envelope-open:before {
  content: "\ea46";
}
.icon-mail-envelope-open2:before {
  content: "\ea47";
}
.icon-mail-envelope-closed:before {
  content: "\ea48";
}
.icon-mail-envelope-closed2:before {
  content: "\ea49";
}
.icon-mail-envelope-open3:before {
  content: "\ea4a";
}
.icon-mail-envelope-open4:before {
  content: "\ea4b";
}
.icon-mail-envelope-open5:before {
  content: "\ea4c";
}
.icon-mail-envelope-open6:before {
  content: "\ea4d";
}
.icon-mail-envelope-closed3:before {
  content: "\ea4e";
}
.icon-mail-envelope-closed4:before {
  content: "\ea4f";
}
.icon-mail-envelope-open7:before {
  content: "\ea50";
}
.icon-mail-envelope-open8:before {
  content: "\ea51";
}
.icon-mail-error:before {
  content: "\ea52";
}
.icon-mail-error2:before {
  content: "\ea53";
}
.icon-mail-checked:before {
  content: "\ea54";
}
.icon-mail-checked2:before {
  content: "\ea55";
}
.icon-mail-cancel:before {
  content: "\ea56";
}
.icon-mail-cancel2:before {
  content: "\ea57";
}
.icon-mail--forbidden:before {
  content: "\ea58";
}
.icon-mail--forbidden2:before {
  content: "\ea59";
}
.icon-mail-add:before {
  content: "\ea5a";
}
.icon-mail-add2:before {
  content: "\ea5b";
}
.icon-mail-remove:before {
  content: "\ea5c";
}
.icon-mail-remove2:before {
  content: "\ea5d";
}
.icon-flag32:before {
  content: "\ea5e";
}
.icon-flag42:before {
  content: "\ea5f";
}
.icon-flag5:before {
  content: "\ea60";
}
.icon-flag6:before {
  content: "\ea61";
}
.icon-flag7:before {
  content: "\ea62";
}
.icon-flag8:before {
  content: "\ea63";
}
.icon-flag9:before {
  content: "\ea64";
}
.icon-flag10:before {
  content: "\ea65";
}
.icon-bookmark22:before {
  content: "\ea66";
}
.icon-bookmark3:before {
  content: "\ea67";
}
.icon-bookmark-add:before {
  content: "\ea68";
}
.icon-bookmark-add2:before {
  content: "\ea69";
}
.icon-bookmark-remove:before {
  content: "\ea6a";
}
.icon-bookmark-remove2:before {
  content: "\ea6b";
}
.icon-eye-hidden:before {
  content: "\ea6c";
}
.icon-eye-hidden2:before {
  content: "\ea6d";
}
.icon-eye2:before {
  content: "\ea6e";
}
.icon-eye3:before {
  content: "\ea6f";
}
.icon-star3:before {
  content: "\ea70";
}
.icon-star4:before {
  content: "\ea71";
}
.icon-key2:before {
  content: "\ea72";
}
.icon-key3:before {
  content: "\ea73";
}
.icon-key4:before {
  content: "\ea74";
}
.icon-key5:before {
  content: "\ea75";
}
.icon-trash-can:before {
  content: "\ea76";
}
.icon-trash-can2:before {
  content: "\ea77";
}
.icon-trash-can3:before {
  content: "\ea78";
}
.icon-trash-can4:before {
  content: "\ea79";
}
.icon-information:before {
  content: "\ea7a";
}
.icon-information2:before {
  content: "\ea7b";
}
.icon-information3:before {
  content: "\ea7c";
}
.icon-information4:before {
  content: "\ea7d";
}
.icon-book22:before {
  content: "\ea7e";
}
.icon-book3:before {
  content: "\ea7f";
}
.icon-book-bookmark:before {
  content: "\ea80";
}
.icon-book-bookmark2:before {
  content: "\ea81";
}
.icon-clipboard-edit:before {
  content: "\ea82";
}
.icon-clipboard-edit2:before {
  content: "\ea83";
}
.icon-clipboard-add:before {
  content: "\ea84";
}
.icon-clipboard-add2:before {
  content: "\ea85";
}
.icon-clipboard-remove:before {
  content: "\ea86";
}
.icon-clipboard-remove2:before {
  content: "\ea87";
}
.icon-clipboard2:before {
  content: "\ea88";
}
.icon-clipboard3:before {
  content: "\ea89";
}
.icon-clipboard-download:before {
  content: "\ea8a";
}
.icon-clipboard-download2:before {
  content: "\ea8b";
}
.icon-clipboard-upload:before {
  content: "\ea8c";
}
.icon-clipboard-upload2:before {
  content: "\ea8d";
}
.icon-clipboard-checked:before {
  content: "\ea8e";
}
.icon-clipboard-checked2:before {
  content: "\ea8f";
}
.icon-clipboard-text2:before {
  content: "\ea90";
}
.icon-clipboard-text22:before {
  content: "\ea91";
}
.icon-clipboard-list:before {
  content: "\ea92";
}
.icon-clipboard-list2:before {
  content: "\ea93";
}
.icon-note2:before {
  content: "\ea94";
}
.icon-note3:before {
  content: "\ea95";
}
.icon-note-add:before {
  content: "\ea96";
}
.icon-note-add2:before {
  content: "\ea97";
}
.icon-note-remove:before {
  content: "\ea98";
}
.icon-note-remove2:before {
  content: "\ea99";
}
.icon-note-text:before {
  content: "\ea9a";
}
.icon-note-text2:before {
  content: "\ea9b";
}
.icon-note-list:before {
  content: "\ea9c";
}
.icon-note-list2:before {
  content: "\ea9d";
}
.icon-note-checked:before {
  content: "\ea9e";
}
.icon-note-checked2:before {
  content: "\ea9f";
}
.icon-note-important:before {
  content: "\eaa0";
}
.icon-note-important2:before {
  content: "\eaa1";
}
.icon-notebook:before {
  content: "\eaa2";
}
.icon-notebook2:before {
  content: "\eaa3";
}
.icon-notebook3:before {
  content: "\eaa4";
}
.icon-notebook4:before {
  content: "\eaa5";
}
.icon-notebook5:before {
  content: "\eaa6";
}
.icon-notebook6:before {
  content: "\eaa7";
}
.icon-notebook7:before {
  content: "\eaa8";
}
.icon-notebook8:before {
  content: "\eaa9";
}
.icon-notebook-text:before {
  content: "\eaaa";
}
.icon-notebook-text2:before {
  content: "\eaab";
}
.icon-notebook-list:before {
  content: "\eaac";
}
.icon-notebook-list2:before {
  content: "\eaad";
}
.icon-document3:before {
  content: "\eaae";
}
.icon-document22:before {
  content: "\eaaf";
}
.icon-document-text:before {
  content: "\eab0";
}
.icon-document-text2:before {
  content: "\eab1";
}
.icon-document-text3:before {
  content: "\eab2";
}
.icon-document-text4:before {
  content: "\eab3";
}
.icon-document-download:before {
  content: "\eab4";
}
.icon-document-download2:before {
  content: "\eab5";
}
.icon-document-upload:before {
  content: "\eab6";
}
.icon-document-upload2:before {
  content: "\eab7";
}
.icon-document-bookmark:before {
  content: "\eab8";
}
.icon-document-bookmark2:before {
  content: "\eab9";
}
.icon-document-diagrams:before {
  content: "\eaba";
}
.icon-document-diagrams2:before {
  content: "\eabb";
}
.icon-document-recording:before {
  content: "\eabc";
}
.icon-document-recording2:before {
  content: "\eabd";
}
.icon-document-table:before {
  content: "\eabe";
}
.icon-document-table2:before {
  content: "\eabf";
}
.icon-document-music:before {
  content: "\eac0";
}
.icon-document-music2:before {
  content: "\eac1";
}
.icon-document-movie:before {
  content: "\eac2";
}
.icon-document-movie2:before {
  content: "\eac3";
}
.icon-document-play:before {
  content: "\eac4";
}
.icon-document-play2:before {
  content: "\eac5";
}
.icon-document-graph:before {
  content: "\eac6";
}
.icon-document-graph2:before {
  content: "\eac7";
}
.icon-document-time:before {
  content: "\eac8";
}
.icon-document-time2:before {
  content: "\eac9";
}
.icon-document-text5:before {
  content: "\eaca";
}
.icon-document-text6:before {
  content: "\eacb";
}
.icon-document-code:before {
  content: "\eacc";
}
.icon-document-code2:before {
  content: "\eacd";
}
.icon-document-cloud:before {
  content: "\eace";
}
.icon-document-cloud2:before {
  content: "\eacf";
}
.icon-documents:before {
  content: "\ead0";
}
.icon-documents2:before {
  content: "\ead1";
}
.icon-documents3:before {
  content: "\ead2";
}
.icon-documents4:before {
  content: "\ead3";
}
.icon-document-search:before {
  content: "\ead4";
}
.icon-document-search2:before {
  content: "\ead5";
}
.icon-document-star:before {
  content: "\ead6";
}
.icon-document-star2:before {
  content: "\ead7";
}
.icon-document-unlocked:before {
  content: "\ead8";
}
.icon-document-unlocked2:before {
  content: "\ead9";
}
.icon-document-locked:before {
  content: "\eada";
}
.icon-document-locked2:before {
  content: "\eadb";
}
.icon-document-error:before {
  content: "\eadc";
}
.icon-document-error2:before {
  content: "\eadd";
}
.icon-document-cancel:before {
  content: "\eade";
}
.icon-document-cancel2:before {
  content: "\eadf";
}
.icon-document-checked:before {
  content: "\eae0";
}
.icon-document-checked2:before {
  content: "\eae1";
}
.icon-document-add:before {
  content: "\eae2";
}
.icon-document-add2:before {
  content: "\eae3";
}
.icon-document-remove:before {
  content: "\eae4";
}
.icon-document-remove2:before {
  content: "\eae5";
}
.icon-document-forbidden:before {
  content: "\eae6";
}
.icon-document-forbidden2:before {
  content: "\eae7";
}
.icon-document-information:before {
  content: "\eae8";
}
.icon-document-information2:before {
  content: "\eae9";
}
.icon-folder-information:before {
  content: "\eaea";
}
.icon-folder-information2:before {
  content: "\eaeb";
}
.icon-document-list:before {
  content: "\eaec";
}
.icon-document-list2:before {
  content: "\eaed";
}
.icon-document-font:before {
  content: "\eaee";
}
.icon-document-font2:before {
  content: "\eaef";
}
.icon-inbox32:before {
  content: "\eaf0";
}
.icon-inbox4:before {
  content: "\eaf1";
}
.icon-inboxes:before {
  content: "\eaf2";
}
.icon-inboxes2:before {
  content: "\eaf3";
}
.icon-inbox-document:before {
  content: "\eaf4";
}
.icon-inbox-document2:before {
  content: "\eaf5";
}
.icon-inbox-document-text:before {
  content: "\eaf6";
}
.icon-inbox-document-text2:before {
  content: "\eaf7";
}
.icon-inbox-download:before {
  content: "\eaf8";
}
.icon-inbox-download2:before {
  content: "\eaf9";
}
.icon-inbox-upload:before {
  content: "\eafa";
}
.icon-inbox-upload2:before {
  content: "\eafb";
}
.icon-folder3:before {
  content: "\eafc";
}
.icon-folder4:before {
  content: "\eafd";
}
.icon-folder5:before {
  content: "\eafe";
}
.icon-folder6:before {
  content: "\eaff";
}
.icon-folders:before {
  content: "\eb00";
}
.icon-folders2:before {
  content: "\eb01";
}
.icon-folder-download2:before {
  content: "\eb02";
}
.icon-folder-download22:before {
  content: "\eb03";
}
.icon-folder-upload2:before {
  content: "\eb04";
}
.icon-folder-upload22:before {
  content: "\eb05";
}
.icon-folder-unlocked:before {
  content: "\eb06";
}
.icon-folder-unlocked2:before {
  content: "\eb07";
}
.icon-folder-locked:before {
  content: "\eb08";
}
.icon-folder-locked2:before {
  content: "\eb09";
}
.icon-folder-search2:before {
  content: "\eb0a";
}
.icon-folder-search22:before {
  content: "\eb0b";
}
.icon-folder-error:before {
  content: "\eb0c";
}
.icon-folder-error2:before {
  content: "\eb0d";
}
.icon-folder-cancel:before {
  content: "\eb0e";
}
.icon-folder-cancel2:before {
  content: "\eb0f";
}
.icon-folder-checked:before {
  content: "\eb10";
}
.icon-folder-checked2:before {
  content: "\eb11";
}
.icon-folder-add:before {
  content: "\eb12";
}
.icon-folder-add2:before {
  content: "\eb13";
}
.icon-folder-remove:before {
  content: "\eb14";
}
.icon-folder-remove2:before {
  content: "\eb15";
}
.icon-folder-forbidden:before {
  content: "\eb16";
}
.icon-folder-forbidden2:before {
  content: "\eb17";
}
.icon-folder-bookmark:before {
  content: "\eb18";
}
.icon-folder-bookmark2:before {
  content: "\eb19";
}
.icon-document-zip:before {
  content: "\eb1a";
}
.icon-document-zip2:before {
  content: "\eb1b";
}
.icon-zip:before {
  content: "\eb1c";
}
.icon-zip2:before {
  content: "\eb1d";
}
.icon-search3:before {
  content: "\eb1e";
}
.icon-search4:before {
  content: "\eb1f";
}
.icon-search-plus2:before {
  content: "\eb20";
}
.icon-search-plus3:before {
  content: "\eb21";
}
.icon-search-minus2:before {
  content: "\eb22";
}
.icon-search-minus3:before {
  content: "\eb23";
}
.icon-lock3:before {
  content: "\eb24";
}
.icon-lock4:before {
  content: "\eb25";
}
.icon-lock-open:before {
  content: "\eb26";
}
.icon-lock-open2:before {
  content: "\eb27";
}
.icon-lock-open3:before {
  content: "\eb28";
}
.icon-lock-open4:before {
  content: "\eb29";
}
.icon-lock-stripes:before {
  content: "\eb2a";
}
.icon-lock-stripes2:before {
  content: "\eb2b";
}
.icon-lock-rounded:before {
  content: "\eb2c";
}
.icon-lock-rounded2:before {
  content: "\eb2d";
}
.icon-lock-rounded-open:before {
  content: "\eb2e";
}
.icon-lock-rounded-open2:before {
  content: "\eb2f";
}
.icon-lock-rounded-open3:before {
  content: "\eb30";
}
.icon-lock-rounded-open4:before {
  content: "\eb31";
}
.icon-combination-lock:before {
  content: "\eb32";
}
.icon-combination-lock2:before {
  content: "\eb33";
}
.icon-printer2:before {
  content: "\eb34";
}
.icon-printer22:before {
  content: "\eb35";
}
.icon-printer3:before {
  content: "\eb36";
}
.icon-printer4:before {
  content: "\eb37";
}
.icon-printer-text:before {
  content: "\eb38";
}
.icon-printer-text2:before {
  content: "\eb39";
}
.icon-printer-text3:before {
  content: "\eb3a";
}
.icon-printer-text4:before {
  content: "\eb4d";
}
.icon-document-shred:before {
  content: "\eb4e";
}
.icon-document-shred2:before {
  content: "\eb4f";
}
.icon-shredder2:before {
  content: "\eb50";
}
.icon-shredder22:before {
  content: "\eb51";
}
.icon-document-scan:before {
  content: "\eb52";
}
.icon-document-scan2:before {
  content: "\eb53";
}
.icon-cloud-download2:before {
  content: "\eb54";
}
.icon-cloud-download3:before {
  content: "\eb55";
}
.icon-cloud-upload2:before {
  content: "\eb56";
}
.icon-cloud-upload3:before {
  content: "\eb57";
}
.icon-cloud-error:before {
  content: "\eb58";
}
.icon-cloud-error2:before {
  content: "\eb59";
}
.icon-cloud2:before {
  content: "\eb5a";
}
.icon-cloud3:before {
  content: "\eb5b";
}
.icon-inbox-filled:before {
  content: "\eb5c";
}
.icon-inbox-filled2:before {
  content: "\eb5d";
}
.icon-pen:before {
  content: "\eb5e";
}
.icon-pen2:before {
  content: "\eb5f";
}
.icon-pen-angled:before {
  content: "\eb60";
}
.icon-pen-angled2:before {
  content: "\eb61";
}
.icon-document-edit:before {
  content: "\eb62";
}
.icon-document-edit2:before {
  content: "\eb63";
}
.icon-document-certificate:before {
  content: "\eb64";
}
.icon-document-certificate2:before {
  content: "\eb65";
}
.icon-certificate2:before {
  content: "\eb66";
}
.icon-certificate3:before {
  content: "\eb67";
}
.icon-package:before {
  content: "\eb68";
}
.icon-package2:before {
  content: "\eb69";
}
.icon-box2:before {
  content: "\eb6a";
}
.icon-box22:before {
  content: "\eb6b";
}
.icon-box-filled:before {
  content: "\eb6c";
}
.icon-box-filled2:before {
  content: "\eb6d";
}
.icon-box3:before {
  content: "\eb6e";
}
.icon-box4:before {
  content: "\eb6f";
}
.icon-box5:before {
  content: "\eb70";
}
.icon-box6:before {
  content: "\eb71";
}
.icon-box-bookmark:before {
  content: "\eb72";
}
.icon-box-bookmark2:before {
  content: "\eb73";
}
.icon-tag-cord:before {
  content: "\eb74";
}
.icon-tag-cord2:before {
  content: "\eb75";
}
.icon-tag2:before {
  content: "\eb76";
}
.icon-tag3:before {
  content: "\eb77";
}
.icon-tags2:before {
  content: "\eb78";
}
.icon-tags3:before {
  content: "\eb79";
}
.icon-tag-add:before {
  content: "\eb7a";
}
.icon-tag-add2:before {
  content: "\eb7b";
}
.icon-tag-remove:before {
  content: "\eb7c";
}
.icon-tag-remove2:before {
  content: "\eb7d";
}
.icon-tag-checked:before {
  content: "\eb7e";
}
.icon-tag-checked2:before {
  content: "\eb7f";
}
.icon-tag-cancel:before {
  content: "\eb80";
}
.icon-tag-cancel2:before {
  content: "\eb81";
}
.icon-paperclip2:before {
  content: "\ebd4";
}
.icon-trophy22:before {
  content: "\ebd2";
}
.icon-trophy3:before {
  content: "\ebd3";
}
.icon-trophy-one:before {
  content: "\eb82";
}
.icon-trophy-one2:before {
  content: "\eb83";
}
.icon-trophy4:before {
  content: "\eb84";
}
.icon-trophy5:before {
  content: "\eb85";
}
.icon-medal:before {
  content: "\eb86";
}
.icon-medal2:before {
  content: "\eb87";
}
.icon-medal3:before {
  content: "\eb88";
}
.icon-medal4:before {
  content: "\eb89";
}
.icon-stop-watch:before {
  content: "\eb8a";
}
.icon-stop-watch2:before {
  content: "\eb8b";
}
.icon-stop-watch3:before {
  content: "\eb8c";
}
.icon-stop-watch4:before {
  content: "\eb8d";
}
.icon-clipboard-move:before {
  content: "\ebd1";
}
.icon-clipboard-move2:before {
  content: "\ebd0";
}
.icon-award:before {
  content: "\eb8e";
}
.icon-award2:before {
  content: "\eb8f";
}
.icon-award3:before {
  content: "\eb90";
}
.icon-award4:before {
  content: "\eb91";
}
.icon-award5:before {
  content: "\eb92";
}
.icon-award6:before {
  content: "\eb93";
}
.icon-award7:before {
  content: "\eb94";
}
.icon-award8:before {
  content: "\eb95";
}
.icon-medal5:before {
  content: "\eb96";
}
.icon-medal6:before {
  content: "\eb97";
}
.icon-medal7:before {
  content: "\eb98";
}
.icon-medal8:before {
  content: "\ebcf";
}
.icon-checkered-flag:before {
  content: "\eb99";
}
.icon-checkered-flag2:before {
  content: "\eb9a";
}
.icon-target-arrow:before {
  content: "\eb9b";
}
.icon-target-arrow2:before {
  content: "\eb9c";
}
.icon-target:before {
  content: "\eb9d";
}
.icon-target2:before {
  content: "\eb9e";
}
.icon-barometer:before {
  content: "\eb9f";
}
.icon-barometer2:before {
  content: "\eba0";
}
.icon-compass-north:before {
  content: "\eba1";
}
.icon-compass-north2:before {
  content: "\eba2";
}
.icon-compass-west:before {
  content: "\eba3";
}
.icon-compass-west2:before {
  content: "\eba4";
}
.icon-compass-east:before {
  content: "\eba5";
}
.icon-compass-east2:before {
  content: "\eba6";
}
.icon-compass-south:before {
  content: "\eba7";
}
.icon-compass-south2:before {
  content: "\eba8";
}
.icon-degree-fahrenheit:before {
  content: "\eba9";
}
.icon-degree-fahrenheit2:before {
  content: "\ebaa";
}
.icon-degree-celsius:before {
  content: "\ebab";
}
.icon-degree-celsius2:before {
  content: "\ebac";
}
.icon-warning3:before {
  content: "\ebad";
}
.icon-warning4:before {
  content: "\ebae";
}
.icon-compass22:before {
  content: "\ebaf";
}
.icon-compass3:before {
  content: "\ebb0";
}
.icon-compass4:before {
  content: "\ebb1";
}
.icon-compass5:before {
  content: "\ebb2";
}
.icon-compass6:before {
  content: "\ebb3";
}
.icon-compass7:before {
  content: "\ebb4";
}
.icon-compass8:before {
  content: "\ebb5";
}
.icon-compass9:before {
  content: "\ebb6";
}
.icon-thermometer2:before {
  content: "\ebb7";
}
.icon-thermometer22:before {
  content: "\ebb8";
}
.icon-thermometer-low:before {
  content: "\ebb9";
}
.icon-thermometer-low2:before {
  content: "\ebba";
}
.icon-thermometer-quarter:before {
  content: "\ebbb";
}
.icon-thermometer-quarter2:before {
  content: "\ebcd";
}
.icon-thermometer-half:before {
  content: "\ebcc";
}
.icon-thermometer-half2:before {
  content: "\ebce";
}
.icon-thermometer-three-quarters:before {
  content: "\ebbc";
}
.icon-thermometer-three-quarters2:before {
  content: "\ebbd";
}
.icon-thermometer-full:before {
  content: "\ebbe";
}
.icon-thermometer-full2:before {
  content: "\ebbf";
}
.icon-lightning:before {
  content: "\ebc0";
}
.icon-lightning2:before {
  content: "\ebc1";
}
.icon-neutral-face:before {
  content: "\ebc2";
}
.icon-neutral-face2:before {
  content: "\ebc3";
}
.icon-smiling-face:before {
  content: "\ebc4";
}
.icon-smiling-face2:before {
  content: "\ebc5";
}
.icon-sad-face:before {
  content: "\ebc6";
}
.icon-sad-face2:before {
  content: "\ebc7";
}
.icon-face-open-mouth:before {
  content: "\ebc8";
}
.icon-face-open-mouth2:before {
  content: "\ebc9";
}
.icon-angry-face-eyebrows:before {
  content: "\ebca";
}
.icon-angry-face-eyebrows2:before {
  content: "\ebcb";
}
.icon-middle-finger:before {
  content: "\ebe4";
}
.icon-middle-finger2:before {
  content: "\ebe5";
}
.icon-rock-n-roll:before {
  content: "\ebe6";
}
.icon-rock-n-roll2:before {
  content: "\ebe7";
}
.icon-high-five:before {
  content: "\ebe8";
}
.icon-high-five2:before {
  content: "\ebe9";
}
.icon-thumb-up:before {
  content: "\ebea";
}
.icon-thumb-up2:before {
  content: "\ebeb";
}
.icon-thumb-down:before {
  content: "\ebec";
}
.icon-thumb-down2:before {
  content: "\ebed";
}
.icon-thumb-up3:before {
  content: "\ebee";
}
.icon-thumb-up4:before {
  content: "\ebef";
}
.icon-thumb-down3:before {
  content: "\ebf0";
}
.icon-thumb-down4:before {
  content: "\ebf1";
}
.icon-two-fingers-swipe-left:before {
  content: "\ebf2";
}
.icon-two-fingers-swipe-left2:before {
  content: "\ebf3";
}
.icon-two-fingers-swipe-right:before {
  content: "\ebf4";
}
.icon-two-fingers-swipe-right2:before {
  content: "\ebf5";
}
.icon-two-fingers-swipe-up:before {
  content: "\ebf6";
}
.icon-two-fingers-swipe-up2:before {
  content: "\ebf7";
}
.icon-two-fingers-swipe-down:before {
  content: "\ebf8";
}
.icon-two-fingers-swipe-down2:before {
  content: "\ebf9";
}
.icon-two-fingers:before {
  content: "\ebfa";
}
.icon-two-fingers2:before {
  content: "\ebfb";
}
.icon-three-fingers-double-tap:before {
  content: "\ebfc";
}
.icon-three-fingers-double-tap2:before {
  content: "\ebfd";
}
.icon-two-fingers-resize-out:before {
  content: "\ebfe";
}
.icon-two-fingers-resize-out2:before {
  content: "\ebff";
}
.icon-two-fingers-resize-in:before {
  content: "\ec00";
}
.icon-two-fingers-resize-in2:before {
  content: "\ec01";
}
.icon-two-fingers-rotate:before {
  content: "\ec02";
}
.icon-two-fingers-rotate2:before {
  content: "\ec03";
}
.icon-one-finger-swipe-left:before {
  content: "\ec04";
}
.icon-one-finger-swipe-left2:before {
  content: "\ec05";
}
.icon-one-finger-swipe-right:before {
  content: "\ec06";
}
.icon-one-finger-swipe-right2:before {
  content: "\ec07";
}
.icon-one-finger-swipe-up:before {
  content: "\ec08";
}
.icon-one-finger-swipe-up2:before {
  content: "\ec09";
}
.icon-one-finger-swipe-down:before {
  content: "\ec0a";
}
.icon-one-finger-swipe-down2:before {
  content: "\ec0b";
}
.icon-one-finger:before {
  content: "\ec0c";
}
.icon-one-finger2:before {
  content: "\ec0d";
}
.icon-one-finger-double-tap:before {
  content: "\ec0e";
}
.icon-one-finger-double-tap2:before {
  content: "\ec0f";
}
.icon-one-finger-tap:before {
  content: "\ec10";
}
.icon-one-finger-tap2:before {
  content: "\ec11";
}
.icon-one-finger-tap-hold:before {
  content: "\ec12";
}
.icon-one-finger-tap-hold2:before {
  content: "\ec13";
}
.icon-thumb-finger-tap:before {
  content: "\ec14";
}
.icon-thumb-finger-tap2:before {
  content: "\ec15";
}
.icon-one-finger-click:before {
  content: "\ec16";
}
.icon-one-finger-click2:before {
  content: "\ec17";
}
.icon-three-fingers-swipe-left:before {
  content: "\ec18";
}
.icon-three-fingers-swipe-left2:before {
  content: "\ec19";
}
.icon-three-fingers-swipe-right:before {
  content: "\ec1a";
}
.icon-three-fingers-swipe-right2:before {
  content: "\ec1b";
}
.icon-three-fingers-swipe-up:before {
  content: "\ec1c";
}
.icon-three-fingers-swipe-up2:before {
  content: "\ec1d";
}
.icon-three-fingers-swipe-down:before {
  content: "\ec1e";
}
.icon-three-fingers-swipe-down2:before {
  content: "\ec1f";
}
.icon-three-fingers:before {
  content: "\ec20";
}
.icon-three-fingers2:before {
  content: "\ec21";
}
.icon-three-fingers-double-tap3:before {
  content: "\ec22";
}
.icon-three-fingers-double-tap4:before {
  content: "\ec23";
}
.icon-file-png:before {
  content: "\ec4a";
}
.icon-file-png2:before {
  content: "\ec4b";
}
.icon-file-pdf:before {
  content: "\ec4c";
}
.icon-file-pdf2:before {
  content: "\ec4d";
}
.icon-file-jpg:before {
  content: "\ec54";
}
.icon-file-jpg2:before {
  content: "\ec55";
}
.icon-file-html:before {
  content: "\ec58";
}
.icon-file-html2:before {
  content: "\ec59";
}
.icon-file-css:before {
  content: "\ec5a";
}
.icon-file-css2:before {
  content: "\ec5b";
}
.icon-file-java:before {
  content: "\ec5c";
}
.icon-file-java2:before {
  content: "\ec5d";
}
.icon-file-bmp:before {
  content: "\ec62";
}
.icon-file-bmp2:before {
  content: "\ec63";
}
.icon-file-eps:before {
  content: "\ec66";
}
.icon-file-eps2:before {
  content: "\ec67";
}
.icon-file-tiff:before {
  content: "\ec68";
}
.icon-file-tiff2:before {
  content: "\ec69";
}
.icon-file-php:before {
  content: "\ec6c";
}
.icon-file-php2:before {
  content: "\ec6d";
}
.icon-file-sql:before {
  content: "\ec72";
}
.icon-file-sql2:before {
  content: "\ec73";
}
.icon-file-doc:before {
  content: "\ec7c";
}
.icon-file-doc2:before {
  content: "\ec7d";
}
.icon-file-xls:before {
  content: "\ec80";
}
.icon-file-xls2:before {
  content: "\ec81";
}
.icon-file-docx:before {
  content: "\ec82";
}
.icon-file-docx2:before {
  content: "\ec83";
}
.icon-file-ppt:before {
  content: "\ec84";
}
.icon-file-ppt2:before {
  content: "\ec85";
}
.icon-file-xml:before {
  content: "\ec8c";
}
.icon-file-xml2:before {
  content: "\ec8d";
}
.icon-file-exe:before {
  content: "\ec92";
}
.icon-file-exe2:before {
  content: "\ec93";
}
.icon-file-avi:before {
  content: "\ec94";
}
.icon-file-avi2:before {
  content: "\ec95";
}
.icon-file-dotx:before {
  content: "\ec98";
}
.icon-file-dotx2:before {
  content: "\ec99";
}
.icon-file-xlsx:before {
  content: "\ec9a";
}
.icon-file-xlsx2:before {
  content: "\ec9b";
}
.icon-file-txt:before {
  content: "\eca2";
}
.icon-file-txt2:before {
  content: "\eca3";
}
.icon-file-rtf:before {
  content: "\eca4";
}
.icon-file-rtf2:before {
  content: "\eca5";
}
.icon-file-mpg:before {
  content: "\ecaa";
}
.icon-file-mpg2:before {
  content: "\ecab";
}
.icon-file-aiff:before {
  content: "\ecb2";
}
.icon-file-aiff2:before {
  content: "\ecb3";
}
.icon-file-aac:before {
  content: "\ecb4";
}
.icon-file-aac2:before {
  content: "\ecb5";
}
.icon-file-wav:before {
  content: "\ecb6";
}
.icon-file-wav2:before {
  content: "\ecb7";
}
.icon-file-zip2:before {
  content: "\ecb8";
}
.icon-file-zip22:before {
  content: "\ecb9";
}
.icon-file-gif:before {
  content: "\ecc4";
}
.icon-file-gif2:before {
  content: "\ecc5";
}
.icon-3d_rotation:before {
  content: "\e84d";
}
.icon-ac_unit:before {
  content: "\eb3b";
}
.icon-alarm3:before {
  content: "\e855";
}
.icon-access_alarms:before {
  content: "\e191";
}
.icon-schedule:before {
  content: "\e8c9";
}
.icon-accessibility:before {
  content: "\e84e";
}
.icon-accessible:before {
  content: "\e921";
}
.icon-account_balance:before {
  content: "\e84f";
}
.icon-account_balance_wallet:before {
  content: "\e850";
}
.icon-account_box:before {
  content: "\e851";
}
.icon-account_circle:before {
  content: "\e853";
}
.icon-adb:before {
  content: "\e60f";
}
.icon-add:before {
  content: "\e145";
}
.icon-add_a_photo:before {
  content: "\e439";
}
.icon-alarm_add:before {
  content: "\e858";
}
.icon-add_alert:before {
  content: "\e003";
}
.icon-add_box:before {
  content: "\e146";
}
.icon-add_circle:before {
  content: "\e147";
}
.icon-control_point:before {
  content: "\e3ba";
}
.icon-add_location:before {
  content: "\e567";
}
.icon-add_shopping_cart:before {
  content: "\e854";
}
.icon-queue:before {
  content: "\e03c";
}
.icon-add_to_queue:before {
  content: "\e05c";
}
.icon-adjust:before {
  content: "\e39e";
}
.icon-airline_seat_flat:before {
  content: "\e630";
}
.icon-airline_seat_flat_angled:before {
  content: "\e631";
}
.icon-airline_seat_individual_suite:before {
  content: "\e633";
}
.icon-airline_seat_legroom_extra:before {
  content: "\e634";
}
.icon-airline_seat_legroom_normal:before {
  content: "\e635";
}
.icon-airline_seat_legroom_reduced:before {
  content: "\e636";
}
.icon-airline_seat_recline_extra:before {
  content: "\e637";
}
.icon-airline_seat_recline_normal:before {
  content: "\e638";
}
.icon-flight:before {
  content: "\e539";
}
.icon-airplanemode_inactive:before {
  content: "\e194";
}
.icon-airplay:before {
  content: "\e055";
}
.icon-airport_shuttle:before {
  content: "\eb3c";
}
.icon-alarm_off:before {
  content: "\e859";
}
.icon-alarm_on:before {
  content: "\e85a";
}
.icon-album:before {
  content: "\e019";
}
.icon-all_inclusive:before {
  content: "\eb3d";
}
.icon-all_out:before {
  content: "\e957";
}
.icon-android:before {
  content: "\e85b";
}
.icon-announcement:before {
  content: "\e85c";
}
.icon-apps:before {
  content: "\e5c3";
}
.icon-archive3:before {
  content: "\e149";
}
.icon-arrow_back:before {
  content: "\e5c4";
}
.icon-arrow_downward:before {
  content: "\e5db";
}
.icon-arrow_drop_down:before {
  content: "\e5c5";
}
.icon-arrow_drop_down_circle:before {
  content: "\e5c6";
}
.icon-arrow_drop_up:before {
  content: "\e5c7";
}
.icon-arrow_forward:before {
  content: "\e5c8";
}
.icon-arrow_upward:before {
  content: "\e5d8";
}
.icon-art_track:before {
  content: "\e060";
}
.icon-aspect_ratio:before {
  content: "\e85d";
}
.icon-poll:before {
  content: "\e804";
}
.icon-assignment:before {
  content: "\e85e";
}
.icon-assignment_ind:before {
  content: "\e85f";
}
.icon-assignment_late:before {
  content: "\e860";
}
.icon-assignment_return:before {
  content: "\e861";
}
.icon-assignment_returned:before {
  content: "\e862";
}
.icon-assignment_turned_in:before {
  content: "\e863";
}
.icon-assistant:before {
  content: "\e39f";
}
.icon-flag4:before {
  content: "\e153";
}
.icon-attach_file:before {
  content: "\e226";
}
.icon-attach_money:before {
  content: "\e227";
}
.icon-attachment:before {
  content: "\e2bc";
}
.icon-audiotrack:before {
  content: "\e3a1";
}
.icon-autorenew:before {
  content: "\e864";
}
.icon-av_timer:before {
  content: "\e01b";
}
.icon-backspace:before {
  content: "\e14a";
}
.icon-cloud_upload:before {
  content: "\e2c3";
}
.icon-battery_alert:before {
  content: "\e19c";
}
.icon-battery_charging_full:before {
  content: "\e1a3";
}
.icon-battery_std:before {
  content: "\e1a5";
}
.icon-battery_unknown:before {
  content: "\e1a6";
}
.icon-beach_access:before {
  content: "\eb3e";
}
.icon-beenhere:before {
  content: "\e52d";
}
.icon-block:before {
  content: "\e14b";
}
.icon-bluetooth:before {
  content: "\e1a7";
}
.icon-bluetooth_searching:before {
  content: "\e1aa";
}
.icon-bluetooth_connected:before {
  content: "\e1a8";
}
.icon-bluetooth_disabled:before {
  content: "\e1a9";
}
.icon-blur_circular:before {
  content: "\e3a2";
}
.icon-blur_linear:before {
  content: "\e3a3";
}
.icon-blur_off:before {
  content: "\e3a4";
}
.icon-blur_on:before {
  content: "\e3a5";
}
.icon-class:before {
  content: "\e86e";
}
.icon-turned_in:before {
  content: "\e958";
}
.icon-turned_in_not:before {
  content: "\e971";
}
.icon-border_all:before {
  content: "\e228";
}
.icon-border_bottom:before {
  content: "\e229";
}
.icon-border_clear:before {
  content: "\e22a";
}
.icon-border_color:before {
  content: "\e22b";
}
.icon-border_horizontal:before {
  content: "\e22c";
}
.icon-border_inner:before {
  content: "\e22d";
}
.icon-border_left:before {
  content: "\e22e";
}
.icon-border_outer:before {
  content: "\e22f";
}
.icon-border_right:before {
  content: "\e230";
}
.icon-border_style:before {
  content: "\e231";
}
.icon-border_top:before {
  content: "\e232";
}
.icon-border_vertical:before {
  content: "\e233";
}
.icon-branding_watermark:before {
  content: "\e06b";
}
.icon-brightness_1:before {
  content: "\e3a6";
}
.icon-brightness_2:before {
  content: "\e3a7";
}
.icon-brightness_3:before {
  content: "\e3a8";
}
.icon-brightness_4:before {
  content: "\e3a9";
}
.icon-brightness_low:before {
  content: "\e1ad";
}
.icon-brightness_medium:before {
  content: "\e1ae";
}
.icon-brightness_high:before {
  content: "\e1ac";
}
.icon-brightness_auto:before {
  content: "\e1ab";
}
.icon-broken_image:before {
  content: "\e3ad";
}
.icon-brush:before {
  content: "\e3ae";
}
.icon-bubble_chart:before {
  content: "\e6e1";
}
.icon-bug_report:before {
  content: "\e868";
}
.icon-build:before {
  content: "\e869";
}
.icon-burst_mode:before {
  content: "\e43c";
}
.icon-domain:before {
  content: "\e7f4";
}
.icon-business_center:before {
  content: "\eb3f";
}
.icon-cached:before {
  content: "\e86a";
}
.icon-cake2:before {
  content: "\e7f5";
}
.icon-phone:before {
  content: "\e0cd";
}
.icon-call_end:before {
  content: "\e0b1";
}
.icon-call_made:before {
  content: "\e0b2";
}
.icon-merge_type:before {
  content: "\e252";
}
.icon-call_missed:before {
  content: "\e0b4";
}
.icon-call_missed_outgoing:before {
  content: "\e0e4";
}
.icon-call_received:before {
  content: "\e0b5";
}
.icon-call_split:before {
  content: "\e0b6";
}
.icon-call_to_action:before {
  content: "\e06c";
}
.icon-camera3:before {
  content: "\e3af";
}
.icon-photo_camera:before {
  content: "\e412";
}
.icon-camera_enhance:before {
  content: "\e972";
}
.icon-camera_front:before {
  content: "\e3b1";
}
.icon-camera_rear:before {
  content: "\e3b2";
}
.icon-camera_roll:before {
  content: "\e3b3";
}
.icon-cancel:before {
  content: "\e5c9";
}
.icon-redeem:before {
  content: "\e8ca";
}
.icon-card_membership:before {
  content: "\e973";
}
.icon-card_travel:before {
  content: "\e974";
}
.icon-casino:before {
  content: "\eb40";
}
.icon-cast:before {
  content: "\e307";
}
.icon-cast_connected:before {
  content: "\e308";
}
.icon-center_focus_strong:before {
  content: "\e3b4";
}
.icon-center_focus_weak:before {
  content: "\e3b5";
}
.icon-change_history:before {
  content: "\e86b";
}
.icon-chat:before {
  content: "\e0b7";
}
.icon-chat_bubble:before {
  content: "\e0ca";
}
.icon-chat_bubble_outline:before {
  content: "\e0cb";
}
.icon-check2:before {
  content: "\e5ca";
}
.icon-check_box:before {
  content: "\e837";
}
.icon-check_box_outline_blank:before {
  content: "\e83b";
}
.icon-check_circle:before {
  content: "\e86c";
}
.icon-navigate_before:before {
  content: "\e408";
}
.icon-navigate_next:before {
  content: "\e409";
}
.icon-child_care:before {
  content: "\eb41";
}
.icon-child_friendly:before {
  content: "\eb42";
}
.icon-chrome_reader_mode:before {
  content: "\e86d";
}
.icon-close:before {
  content: "\e5cd";
}
.icon-clear_all:before {
  content: "\e0b8";
}
.icon-closed_caption:before {
  content: "\e01c";
}
.icon-wb_cloudy:before {
  content: "\e42d";
}
.icon-cloud_circle:before {
  content: "\e2be";
}
.icon-cloud_done:before {
  content: "\e2bf";
}
.icon-cloud_download:before {
  content: "\e2c0";
}
.icon-cloud_off:before {
  content: "\e2c1";
}
.icon-cloud_queue:before {
  content: "\e2c2";
}
.icon-code2:before {
  content: "\e86f";
}
.icon-photo_library:before {
  content: "\e413";
}
.icon-collections_bookmark:before {
  content: "\e431";
}
.icon-palette:before {
  content: "\e40a";
}
.icon-colorize:before {
  content: "\e3b8";
}
.icon-comment:before {
  content: "\e0b9";
}
.icon-compare2:before {
  content: "\e3b9";
}
.icon-compare_arrows:before {
  content: "\e979";
}
.icon-laptop2:before {
  content: "\e31e";
}
.icon-confirmation_number:before {
  content: "\e639";
}
.icon-contact_mail:before {
  content: "\e0d0";
}
.icon-contact_phone:before {
  content: "\e0cf";
}
.icon-contacts2:before {
  content: "\e0ba";
}
.icon-content_copy:before {
  content: "\e14d";
}
.icon-content_cut:before {
  content: "\e14e";
}
.icon-content_paste:before {
  content: "\e14f";
}
.icon-control_point_duplicate:before {
  content: "\e3bb";
}
.icon-copyright:before {
  content: "\e97a";
}
.icon-mode_edit:before {
  content: "\e254";
}
.icon-create_new_folder:before {
  content: "\e2cc";
}
.icon-payment:before {
  content: "\e8a1";
}
.icon-crop2:before {
  content: "\e3be";
}
.icon-crop_16_9:before {
  content: "\e3bc";
}
.icon-crop_3_2:before {
  content: "\e3bd";
}
.icon-crop_landscape:before {
  content: "\e3c3";
}
.icon-crop_7_5:before {
  content: "\e3c0";
}
.icon-crop_din:before {
  content: "\e3c1";
}
.icon-crop_free:before {
  content: "\e3c2";
}
.icon-crop_original:before {
  content: "\e3c4";
}
.icon-crop_portrait:before {
  content: "\e3c5";
}
.icon-crop_rotate:before {
  content: "\e437";
}
.icon-crop_square:before {
  content: "\e3c6";
}
.icon-dashboard:before {
  content: "\e871";
}
.icon-data_usage:before {
  content: "\e1af";
}
.icon-date_range:before {
  content: "\e97d";
}
.icon-dehaze:before {
  content: "\e3c7";
}
.icon-delete2:before {
  content: "\e872";
}
.icon-delete_forever:before {
  content: "\e97e";
}
.icon-delete_sweep:before {
  content: "\e16c";
}
.icon-description:before {
  content: "\e873";
}
.icon-desktop_mac:before {
  content: "\e30b";
}
.icon-desktop_windows:before {
  content: "\e30c";
}
.icon-details:before {
  content: "\e3c8";
}
.icon-developer_board:before {
  content: "\e30d";
}
.icon-developer_mode:before {
  content: "\e1b0";
}
.icon-device_hub:before {
  content: "\e335";
}
.icon-phonelink:before {
  content: "\e326";
}
.icon-devices_other:before {
  content: "\e337";
}
.icon-dialer_sip:before {
  content: "\e0bb";
}
.icon-dialpad:before {
  content: "\e0bc";
}
.icon-directions:before {
  content: "\e52e";
}
.icon-directions_bike:before {
  content: "\e52f";
}
.icon-directions_boat:before {
  content: "\e532";
}
.icon-directions_bus:before {
  content: "\e530";
}
.icon-directions_car:before {
  content: "\e531";
}
.icon-directions_railway:before {
  content: "\e534";
}
.icon-directions_run:before {
  content: "\e566";
}
.icon-directions_transit:before {
  content: "\e535";
}
.icon-directions_walk:before {
  content: "\e536";
}
.icon-disc_full:before {
  content: "\e610";
}
.icon-dns:before {
  content: "\e875";
}
.icon-not_interested:before {
  content: "\e033";
}
.icon-do_not_disturb_alt:before {
  content: "\e614";
}
.icon-do_not_disturb_off:before {
  content: "\e643";
}
.icon-remove_circle:before {
  content: "\e15c";
}
.icon-dock:before {
  content: "\e30e";
}
.icon-done:before {
  content: "\e877";
}
.icon-done_all:before {
  content: "\e878";
}
.icon-donut_large:before {
  content: "\e97f";
}
.icon-donut_small:before {
  content: "\e980";
}
.icon-drafts:before {
  content: "\e151";
}
.icon-drag_handle:before {
  content: "\e25d";
}
.icon-time_to_leave:before {
  content: "\e62c";
}
.icon-dvr:before {
  content: "\e1b2";
}
.icon-edit_location:before {
  content: "\e568";
}
.icon-eject:before {
  content: "\e981";
}
.icon-markunread:before {
  content: "\e159";
}
.icon-enhanced_encryption:before {
  content: "\e63f";
}
.icon-equalizer2:before {
  content: "\e01d";
}
.icon-error:before {
  content: "\e000";
}
.icon-error_outline:before {
  content: "\e001";
}
.icon-euro_symbol:before {
  content: "\e982";
}
.icon-ev_station:before {
  content: "\e56d";
}
.icon-insert_invitation:before {
  content: "\e24f";
}
.icon-event_available:before {
  content: "\e615";
}
.icon-event_busy:before {
  content: "\e616";
}
.icon-event_note:before {
  content: "\e617";
}
.icon-event_seat:before {
  content: "\e983";
}
.icon-exit_to_app:before {
  content: "\e879";
}
.icon-expand_less:before {
  content: "\e5ce";
}
.icon-expand_more:before {
  content: "\e5cf";
}
.icon-explicit:before {
  content: "\e01e";
}
.icon-explore:before {
  content: "\e87b";
}
.icon-exposure:before {
  content: "\e3ca";
}
.icon-exposure_neg_1:before {
  content: "\e3cb";
}
.icon-exposure_neg_2:before {
  content: "\e3cc";
}
.icon-exposure_plus_1:before {
  content: "\e3cd";
}
.icon-exposure_plus_2:before {
  content: "\e3ce";
}
.icon-exposure_zero:before {
  content: "\e3cf";
}
.icon-extension:before {
  content: "\e87c";
}
.icon-face:before {
  content: "\e87d";
}
.icon-fast_forward:before {
  content: "\e01f";
}
.icon-fast_rewind:before {
  content: "\e020";
}
.icon-favorite:before {
  content: "\e87e";
}
.icon-favorite_border:before {
  content: "\e87f";
}
.icon-featured_play_list:before {
  content: "\e06d";
}
.icon-featured_video:before {
  content: "\e06e";
}
.icon-sms_failed:before {
  content: "\e626";
}
.icon-fiber_dvr:before {
  content: "\e05d";
}
.icon-fiber_manual_record:before {
  content: "\e061";
}
.icon-fiber_new:before {
  content: "\e05e";
}
.icon-fiber_pin:before {
  content: "\e06a";
}
.icon-fiber_smart_record:before {
  content: "\e062";
}
.icon-get_app:before {
  content: "\e888";
}
.icon-file_upload:before {
  content: "\e2c6";
}
.icon-filter:before {
  content: "\e3d3";
}
.icon-filter_1:before {
  content: "\e3d0";
}
.icon-filter_2:before {
  content: "\e3d1";
}
.icon-filter_3:before {
  content: "\e3d2";
}
.icon-filter_4:before {
  content: "\e3d4";
}
.icon-filter_5:before {
  content: "\e3d5";
}
.icon-filter_6:before {
  content: "\e3d6";
}
.icon-filter_7:before {
  content: "\e3d7";
}
.icon-filter_8:before {
  content: "\e3d8";
}
.icon-filter_9:before {
  content: "\e3d9";
}
.icon-filter_9_plus:before {
  content: "\e3da";
}
.icon-filter_b_and_w:before {
  content: "\e3db";
}
.icon-filter_center_focus:before {
  content: "\e3dc";
}
.icon-filter_drama:before {
  content: "\e3dd";
}
.icon-filter_frames:before {
  content: "\e3de";
}
.icon-terrain:before {
  content: "\e564";
}
.icon-filter_list:before {
  content: "\e152";
}
.icon-filter_none:before {
  content: "\e3e0";
}
.icon-filter_tilt_shift:before {
  content: "\e3e2";
}
.icon-filter_vintage:before {
  content: "\e3e3";
}
.icon-find_in_page:before {
  content: "\e889";
}
.icon-find_replace:before {
  content: "\e88a";
}
.icon-fingerprint:before {
  content: "\e984";
}
.icon-first_page:before {
  content: "\e5dc";
}
.icon-fitness_center:before {
  content: "\eb43";
}
.icon-flare:before {
  content: "\e3e4";
}
.icon-flash_auto:before {
  content: "\e3e5";
}
.icon-flash_off:before {
  content: "\e3e6";
}
.icon-flash_on:before {
  content: "\e3e7";
}
.icon-flight_land:before {
  content: "\e985";
}
.icon-flight_takeoff:before {
  content: "\e99e";
}
.icon-flip:before {
  content: "\e3e8";
}
.icon-flip_to_back:before {
  content: "\e88b";
}
.icon-flip_to_front:before {
  content: "\e88c";
}
.icon-folder2:before {
  content: "\e2c7";
}
.icon-folder_open:before {
  content: "\e2c8";
}
.icon-folder_shared:before {
  content: "\e2c9";
}
.icon-folder_special:before {
  content: "\e618";
}
.icon-font_download:before {
  content: "\e167";
}
.icon-format_align_center:before {
  content: "\e234";
}
.icon-format_align_justify:before {
  content: "\e235";
}
.icon-format_align_left:before {
  content: "\e236";
}
.icon-format_align_right:before {
  content: "\e237";
}
.icon-format_bold:before {
  content: "\e238";
}
.icon-format_clear:before {
  content: "\e239";
}
.icon-format_color_fill:before {
  content: "\e23a";
}
.icon-format_color_reset:before {
  content: "\e23b";
}
.icon-format_color_text:before {
  content: "\e23c";
}
.icon-format_indent_decrease:before {
  content: "\e23d";
}
.icon-format_indent_increase:before {
  content: "\e23e";
}
.icon-format_italic:before {
  content: "\e23f";
}
.icon-format_line_spacing:before {
  content: "\e240";
}
.icon-format_list_bulleted:before {
  content: "\e241";
}
.icon-format_list_numbered:before {
  content: "\e242";
}
.icon-format_paint:before {
  content: "\e243";
}
.icon-format_quote:before {
  content: "\e244";
}
.icon-format_shapes:before {
  content: "\e25e";
}
.icon-format_size:before {
  content: "\e245";
}
.icon-format_strikethrough:before {
  content: "\e246";
}
.icon-format_textdirection_l_to_r:before {
  content: "\e247";
}
.icon-format_textdirection_r_to_l:before {
  content: "\e248";
}
.icon-format_underlined:before {
  content: "\e249";
}
.icon-question_answer:before {
  content: "\e8cd";
}
.icon-forward:before {
  content: "\e154";
}
.icon-forward_10:before {
  content: "\e056";
}
.icon-forward_30:before {
  content: "\e057";
}
.icon-forward_5:before {
  content: "\e058";
}
.icon-free_breakfast:before {
  content: "\eb44";
}
.icon-fullscreen:before {
  content: "\e5d0";
}
.icon-fullscreen_exit:before {
  content: "\e5d1";
}
.icon-functions:before {
  content: "\e24a";
}
.icon-g_translate:before {
  content: "\e99f";
}
.icon-games:before {
  content: "\e021";
}
.icon-gavel:before {
  content: "\e9a0";
}
.icon-gesture:before {
  content: "\e155";
}
.icon-gif:before {
  content: "\e9a1";
}
.icon-goat:before {
  content: "\e9a6";
}
.icon-golf_course:before {
  content: "\eb45";
}
.icon-my_location:before {
  content: "\e55c";
}
.icon-location_searching:before {
  content: "\e1b7";
}
.icon-location_disabled:before {
  content: "\e1b6";
}
.icon-star2:before {
  content: "\e83c";
}
.icon-gradient:before {
  content: "\e3e9";
}
.icon-grain:before {
  content: "\e3ea";
}
.icon-graphic_eq:before {
  content: "\e1b8";
}
.icon-grid_off:before {
  content: "\e3eb";
}
.icon-grid_on:before {
  content: "\e3ec";
}
.icon-people:before {
  content: "\e805";
}
.icon-group_add:before {
  content: "\e806";
}
.icon-group_work:before {
  content: "\e88d";
}
.icon-hd:before {
  content: "\e052";
}
.icon-hdr_off:before {
  content: "\e3ed";
}
.icon-hdr_on:before {
  content: "\e3ee";
}
.icon-hdr_strong:before {
  content: "\e3f1";
}
.icon-hdr_weak:before {
  content: "\e3f2";
}
.icon-headset:before {
  content: "\e310";
}
.icon-headset_mic:before {
  content: "\e311";
}
.icon-healing:before {
  content: "\e3f3";
}
.icon-hearing2:before {
  content: "\e023";
}
.icon-help:before {
  content: "\e88e";
}
.icon-help_outline:before {
  content: "\e9ad";
}
.icon-high_quality:before {
  content: "\e024";
}
.icon-highlight2:before {
  content: "\e25f";
}
.icon-highlight_off:before {
  content: "\e88f";
}
.icon-restore:before {
  content: "\e8ce";
}
.icon-home2:before {
  content: "\e890";
}
.icon-hot_tub:before {
  content: "\eb46";
}
.icon-local_hotel:before {
  content: "\e549";
}
.icon-hourglass_empty:before {
  content: "\e891";
}
.icon-hourglass_full:before {
  content: "\e892";
}
.icon-http:before {
  content: "\e9ae";
}
.icon-lock2:before {
  content: "\e897";
}
.icon-photo:before {
  content: "\e410";
}
.icon-image_aspect_ratio:before {
  content: "\e3f5";
}
.icon-import_contacts:before {
  content: "\e0e0";
}
.icon-import_export:before {
  content: "\e0c3";
}
.icon-important_devices:before {
  content: "\e9bd";
}
.icon-inbox3:before {
  content: "\e156";
}
.icon-indeterminate_check_box:before {
  content: "\e9ea";
}
.icon-info:before {
  content: "\e893";
}
.icon-info_outline:before {
  content: "\e894";
}
.icon-input:before {
  content: "\e895";
}
.icon-insert_comment:before {
  content: "\e24c";
}
.icon-insert_drive_file:before {
  content: "\e24d";
}
.icon-tag_faces:before {
  content: "\e420";
}
.icon-link3:before {
  content: "\e157";
}
.icon-invert_colors:before {
  content: "\e896";
}
.icon-invert_colors_off:before {
  content: "\e0c4";
}
.icon-iso:before {
  content: "\e3f6";
}
.icon-keyboard2:before {
  content: "\e312";
}
.icon-keyboard_arrow_down:before {
  content: "\e313";
}
.icon-keyboard_arrow_left:before {
  content: "\e314";
}
.icon-keyboard_arrow_right:before {
  content: "\e315";
}
.icon-keyboard_arrow_up:before {
  content: "\e316";
}
.icon-keyboard_backspace:before {
  content: "\e317";
}
.icon-keyboard_capslock:before {
  content: "\e318";
}
.icon-keyboard_hide:before {
  content: "\e31a";
}
.icon-keyboard_return:before {
  content: "\e31b";
}
.icon-keyboard_tab:before {
  content: "\e31c";
}
.icon-keyboard_voice:before {
  content: "\e31d";
}
.icon-kitchen:before {
  content: "\eb47";
}
.icon-label2:before {
  content: "\e898";
}
.icon-label_outline:before {
  content: "\e899";
}
.icon-language:before {
  content: "\e89a";
}
.icon-laptop_chromebook:before {
  content: "\e31f";
}
.icon-laptop_mac:before {
  content: "\e320";
}
.icon-laptop_windows:before {
  content: "\e321";
}
.icon-last_page:before {
  content: "\e5dd";
}
.icon-open_in_new:before {
  content: "\e89e";
}
.icon-layers2:before {
  content: "\e53b";
}
.icon-layers_clear:before {
  content: "\e53c";
}
.icon-leak_add:before {
  content: "\e3f8";
}
.icon-leak_remove:before {
  content: "\e3f9";
}
.icon-lens:before {
  content: "\e3fa";
}
.icon-library_books:before {
  content: "\e02f";
}
.icon-library_music:before {
  content: "\e030";
}
.icon-lightbulb_outline:before {
  content: "\e9eb";
}
.icon-line_style:before {
  content: "\e9ec";
}
.icon-line_weight:before {
  content: "\e9ed";
}
.icon-linear_scale:before {
  content: "\e260";
}
.icon-linked_camera:before {
  content: "\e438";
}
.icon-list5:before {
  content: "\e89b";
}
.icon-live_help:before {
  content: "\e0c6";
}
.icon-live_tv:before {
  content: "\e63a";
}
.icon-local_play:before {
  content: "\e553";
}
.icon-local_airport:before {
  content: "\e53d";
}
.icon-local_atm:before {
  content: "\e53e";
}
.icon-local_bar:before {
  content: "\e540";
}
.icon-local_cafe:before {
  content: "\e541";
}
.icon-local_car_wash:before {
  content: "\e542";
}
.icon-local_convenience_store:before {
  content: "\e543";
}
.icon-restaurant_menu:before {
  content: "\e561";
}
.icon-local_drink:before {
  content: "\e544";
}
.icon-local_florist:before {
  content: "\e545";
}
.icon-local_gas_station:before {
  content: "\e546";
}
.icon-shopping_cart:before {
  content: "\e8cf";
}
.icon-local_hospital:before {
  content: "\e548";
}
.icon-local_laundry_service:before {
  content: "\e54a";
}
.icon-local_library:before {
  content: "\e54b";
}
.icon-local_mall:before {
  content: "\e54c";
}
.icon-theaters:before {
  content: "\e9ee";
}
.icon-local_offer:before {
  content: "\e54e";
}
.icon-local_parking:before {
  content: "\e54f";
}
.icon-local_pharmacy:before {
  content: "\e550";
}
.icon-local_pizza:before {
  content: "\e552";
}
.icon-print:before {
  content: "\e8d0";
}
.icon-local_shipping:before {
  content: "\e558";
}
.icon-local_taxi:before {
  content: "\e559";
}
.icon-location_city:before {
  content: "\e809";
}
.icon-location_off:before {
  content: "\e0c7";
}
.icon-room:before {
  content: "\e8d1";
}
.icon-lock_open:before {
  content: "\e89c";
}
.icon-lock_outline:before {
  content: "\e89d";
}
.icon-looks:before {
  content: "\e3fc";
}
.icon-looks_3:before {
  content: "\e3fb";
}
.icon-looks_4:before {
  content: "\e3fd";
}
.icon-looks_5:before {
  content: "\e3fe";
}
.icon-looks_6:before {
  content: "\e3ff";
}
.icon-looks_one:before {
  content: "\e400";
}
.icon-looks_two:before {
  content: "\e401";
}
.icon-sync3:before {
  content: "\e627";
}
.icon-loupe2:before {
  content: "\e402";
}
.icon-low_priority:before {
  content: "\e16d";
}
.icon-loyalty:before {
  content: "\e89f";
}
.icon-mail_outline:before {
  content: "\e0e1";
}
.icon-map:before {
  content: "\e55b";
}
.icon-markunread_mailbox:before {
  content: "\e8a0";
}
.icon-memory:before {
  content: "\e322";
}
.icon-menu4:before {
  content: "\e5d2";
}
.icon-message:before {
  content: "\e0c9";
}
.icon-mic2:before {
  content: "\e029";
}
.icon-mic_none:before {
  content: "\e02a";
}
.icon-mic_off:before {
  content: "\e02b";
}
.icon-mms:before {
  content: "\e619";
}
.icon-mode_comment:before {
  content: "\e253";
}
.icon-monetization_on:before {
  content: "\e263";
}
.icon-money_off:before {
  content: "\e25c";
}
.icon-monochrome_photos:before {
  content: "\e403";
}
.icon-mood_bad:before {
  content: "\e80a";
}
.icon-more:before {
  content: "\e61a";
}
.icon-more_horiz:before {
  content: "\e5d3";
}
.icon-more_vert:before {
  content: "\e5d4";
}
.icon-motorcycle:before {
  content: "\e9ef";
}
.icon-mouse2:before {
  content: "\e323";
}
.icon-move_to_inbox:before {
  content: "\e168";
}
.icon-movie_creation:before {
  content: "\e404";
}
.icon-movie_filter:before {
  content: "\e43a";
}
.icon-multiline_chart:before {
  content: "\e6e2";
}
.icon-music_note:before {
  content: "\e405";
}
.icon-music_video:before {
  content: "\e063";
}
.icon-nature:before {
  content: "\e406";
}
.icon-nature_people:before {
  content: "\e407";
}
.icon-navigation:before {
  content: "\e55d";
}
.icon-near_me:before {
  content: "\e569";
}
.icon-network_cell:before {
  content: "\e1b9";
}
.icon-network_check:before {
  content: "\e640";
}
.icon-network_locked:before {
  content: "\e61b";
}
.icon-network_wifi:before {
  content: "\e1ba";
}
.icon-new_releases:before {
  content: "\e031";
}
.icon-next_week:before {
  content: "\e16a";
}
.icon-nfc:before {
  content: "\e1bb";
}
.icon-no_encryption:before {
  content: "\e641";
}
.icon-signal_cellular_no_sim:before {
  content: "\e1ce";
}
.icon-note:before {
  content: "\e06f";
}
.icon-note_add:before {
  content: "\e8a2";
}
.icon-notifications:before {
  content: "\e80b";
}
.icon-notifications_active:before {
  content: "\e80c";
}
.icon-notifications_none:before {
  content: "\e80d";
}
.icon-notifications_off:before {
  content: "\e80e";
}
.icon-notifications_paused:before {
  content: "\e80f";
}
.icon-offline_pin:before {
  content: "\e9f0";
}
.icon-ondemand_video:before {
  content: "\e63b";
}
.icon-opacity:before {
  content: "\e9f1";
}
.icon-open_in_browser:before {
  content: "\e8a3";
}
.icon-open_with:before {
  content: "\e8a4";
}
.icon-pages:before {
  content: "\e810";
}
.icon-pageview:before {
  content: "\e8a5";
}
.icon-pan_tool:before {
  content: "\e9f2";
}
.icon-panorama:before {
  content: "\e40b";
}
.icon-radio_button_unchecked:before {
  content: "\e83f";
}
.icon-panorama_horizontal:before {
  content: "\e40d";
}
.icon-panorama_vertical:before {
  content: "\e40e";
}
.icon-panorama_wide_angle:before {
  content: "\e40f";
}
.icon-party_mode:before {
  content: "\e813";
}
.icon-pause:before {
  content: "\e034";
}
.icon-pause_circle_filled:before {
  content: "\e035";
}
.icon-pause_circle_outline:before {
  content: "\e036";
}
.icon-people_outline:before {
  content: "\e814";
}
.icon-perm_camera_mic:before {
  content: "\e8a6";
}
.icon-perm_contact_calendar:before {
  content: "\e8a7";
}
.icon-perm_data_setting:before {
  content: "\e8a8";
}
.icon-perm_device_information:before {
  content: "\e8a9";
}
.icon-person_outline:before {
  content: "\e815";
}
.icon-perm_media:before {
  content: "\e8d2";
}
.icon-perm_phone_msg:before {
  content: "\e9f3";
}
.icon-perm_scan_wifi:before {
  content: "\e9f4";
}
.icon-person:before {
  content: "\e816";
}
.icon-person_add:before {
  content: "\e817";
}
.icon-person_pin:before {
  content: "\e55a";
}
.icon-person_pin_circle:before {
  content: "\e56a";
}
.icon-personal_video:before {
  content: "\e63c";
}
.icon-pets:before {
  content: "\e9f5";
}
.icon-phone_android:before {
  content: "\e324";
}
.icon-phone_bluetooth_speaker:before {
  content: "\e61c";
}
.icon-phone_forwarded:before {
  content: "\e61d";
}
.icon-phone_in_talk:before {
  content: "\e61f";
}
.icon-phone_iphone:before {
  content: "\e325";
}
.icon-phone_locked:before {
  content: "\e620";
}
.icon-phone_missed:before {
  content: "\e621";
}
.icon-phone_paused:before {
  content: "\e622";
}
.icon-phonelink_erase:before {
  content: "\e0db";
}
.icon-phonelink_lock:before {
  content: "\e0dc";
}
.icon-phonelink_off:before {
  content: "\e327";
}
.icon-phonelink_ring:before {
  content: "\e0dd";
}
.icon-phonelink_setup:before {
  content: "\e0de";
}
.icon-photo_album:before {
  content: "\e411";
}
.icon-photo_filter:before {
  content: "\e43b";
}
.icon-photo_size_select_actual:before {
  content: "\e432";
}
.icon-photo_size_select_large:before {
  content: "\e433";
}
.icon-photo_size_select_small:before {
  content: "\e434";
}
.icon-picture_as_pdf:before {
  content: "\e415";
}
.icon-picture_in_picture:before {
  content: "\e9f6";
}
.icon-picture_in_picture_alt:before {
  content: "\e9f7";
}
.icon-pie_chart:before {
  content: "\e6c4";
}
.icon-pie_chart_outlined:before {
  content: "\e6c5";
}
.icon-pin_drop:before {
  content: "\e55e";
}
.icon-play_arrow:before {
  content: "\e037";
}
.icon-play_circle_filled:before {
  content: "\e038";
}
.icon-play_circle_outline:before {
  content: "\e039";
}
.icon-play_for_work:before {
  content: "\e9f8";
}
.icon-playlist_add:before {
  content: "\e03b";
}
.icon-playlist_add_check:before {
  content: "\e065";
}
.icon-playlist_play:before {
  content: "\e05f";
}
.icon-plus_one:before {
  content: "\e818";
}
.icon-polymer:before {
  content: "\e9f9";
}
.icon-pool:before {
  content: "\eb48";
}
.icon-portable_wifi_off:before {
  content: "\e0ce";
}
.icon-portrait3:before {
  content: "\e416";
}
.icon-power2:before {
  content: "\e63d";
}
.icon-power_input:before {
  content: "\e336";
}
.icon-power_settings_new:before {
  content: "\e9fa";
}
.icon-pregnant_woman:before {
  content: "\e9fb";
}
.icon-present_to_all:before {
  content: "\e0df";
}
.icon-priority_high:before {
  content: "\e645";
}
.icon-public:before {
  content: "\e819";
}
.icon-publish:before {
  content: "\e255";
}
.icon-queue_music:before {
  content: "\e03d";
}
.icon-queue_play_next:before {
  content: "\e066";
}
.icon-radio:before {
  content: "\e03e";
}
.icon-radio_button_checked:before {
  content: "\e840";
}
.icon-rate_review:before {
  content: "\e560";
}
.icon-receipt2:before {
  content: "\e9fc";
}
.icon-recent_actors:before {
  content: "\e03f";
}
.icon-record_voice_over:before {
  content: "\e9fd";
}
.icon-redo3:before {
  content: "\e15a";
}
.icon-refresh3:before {
  content: "\e5d5";
}
.icon-remove:before {
  content: "\e15b";
}
.icon-remove_circle_outline:before {
  content: "\e15d";
}
.icon-remove_from_queue:before {
  content: "\e067";
}
.icon-visibility:before {
  content: "\e9fe";
}
.icon-remove_shopping_cart:before {
  content: "\e9ff";
}
.icon-reorder:before {
  content: "\ea00";
}
.icon-repeat2:before {
  content: "\e040";
}
.icon-repeat_one:before {
  content: "\e041";
}
.icon-replay:before {
  content: "\e042";
}
.icon-replay_10:before {
  content: "\e059";
}
.icon-replay_30:before {
  content: "\e05a";
}
.icon-replay_5:before {
  content: "\e05b";
}
.icon-reply2:before {
  content: "\e15e";
}
.icon-reply_all:before {
  content: "\e15f";
}
.icon-report:before {
  content: "\e160";
}
.icon-warning2:before {
  content: "\e002";
}
.icon-restaurant:before {
  content: "\e56c";
}
.icon-restore_page:before {
  content: "\ea01";
}
.icon-ring_volume:before {
  content: "\e0d1";
}
.icon-room_service:before {
  content: "\eb49";
}
.icon-rotate_90_degrees_ccw:before {
  content: "\e418";
}
.icon-rotate_left:before {
  content: "\e419";
}
.icon-rotate_right:before {
  content: "\e41a";
}
.icon-rounded_corner:before {
  content: "\ea02";
}
.icon-router:before {
  content: "\e328";
}
.icon-rowing:before {
  content: "\ea03";
}
.icon-rss_feed:before {
  content: "\e0e5";
}
.icon-rv_hookup:before {
  content: "\e642";
}
.icon-satellite:before {
  content: "\e562";
}
.icon-save:before {
  content: "\e161";
}
.icon-scanner:before {
  content: "\e329";
}
.icon-school:before {
  content: "\e81a";
}
.icon-screen_lock_landscape:before {
  content: "\e1be";
}
.icon-screen_lock_portrait:before {
  content: "\e1bf";
}
.icon-screen_lock_rotation:before {
  content: "\e1c0";
}
.icon-screen_rotation:before {
  content: "\e1c1";
}
.icon-screen_share:before {
  content: "\e0e2";
}
.icon-sd_storage:before {
  content: "\e1c2";
}
.icon-search:before {
  content: "\ea04";
}
.icon-security:before {
  content: "\e32a";
}
.icon-select_all:before {
  content: "\e162";
}
.icon-send:before {
  content: "\e163";
}
.icon-sentiment_dissatisfied:before {
  content: "\e81b";
}
.icon-sentiment_neutral:before {
  content: "\e81c";
}
.icon-sentiment_satisfied:before {
  content: "\e81d";
}
.icon-sentiment_very_dissatisfied:before {
  content: "\e81e";
}
.icon-sentiment_very_satisfied:before {
  content: "\e81f";
}
.icon-settings:before {
  content: "\ea05";
}
.icon-settings_applications:before {
  content: "\ea06";
}
.icon-settings_backup_restore:before {
  content: "\ea07";
}
.icon-settings_bluetooth:before {
  content: "\ea08";
}
.icon-settings_brightness:before {
  content: "\ea09";
}
.icon-settings_cell:before {
  content: "\ea0a";
}
.icon-settings_ethernet:before {
  content: "\ea0b";
}
.icon-settings_input_antenna:before {
  content: "\ea0c";
}
.icon-settings_input_composite:before {
  content: "\ea0d";
}
.icon-settings_input_hdmi:before {
  content: "\ea0e";
}
.icon-settings_input_svideo:before {
  content: "\ea0f";
}
.icon-settings_overscan:before {
  content: "\ea10";
}
.icon-settings_phone:before {
  content: "\ea11";
}
.icon-settings_power:before {
  content: "\ea12";
}
.icon-settings_remote:before {
  content: "\ea13";
}
.icon-settings_system_daydream:before {
  content: "\e1c3";
}
.icon-settings_voice:before {
  content: "\ea14";
}
.icon-share3:before {
  content: "\e820";
}
.icon-shop:before {
  content: "\ea15";
}
.icon-shop_two:before {
  content: "\ea16";
}
.icon-shopping_basket:before {
  content: "\ea17";
}
.icon-short_text:before {
  content: "\e261";
}
.icon-show_chart:before {
  content: "\e6e4";
}
.icon-shuffle:before {
  content: "\e043";
}
.icon-signal_cellular_4_bar:before {
  content: "\e1c8";
}
.icon-signal_cellular_connected_no_internet_4_bar:before {
  content: "\e1cd";
}
.icon-signal_cellular_null:before {
  content: "\e1cf";
}
.icon-signal_cellular_off:before {
  content: "\e1d0";
}
.icon-signal_wifi_4_bar:before {
  content: "\e1d8";
}
.icon-signal_wifi_4_bar_lock:before {
  content: "\e1d9";
}
.icon-signal_wifi_off:before {
  content: "\e1da";
}
.icon-sim_card:before {
  content: "\e32b";
}
.icon-sim_card_alert:before {
  content: "\e624";
}
.icon-skip_next:before {
  content: "\e044";
}
.icon-skip_previous:before {
  content: "\e045";
}
.icon-slideshow:before {
  content: "\e41b";
}
.icon-slow_motion_video:before {
  content: "\e068";
}
.icon-stay_primary_portrait:before {
  content: "\e0d6";
}
.icon-smoke_free:before {
  content: "\eb4a";
}
.icon-smoking_rooms:before {
  content: "\eb4b";
}
.icon-textsms:before {
  content: "\e0d8";
}
.icon-snooze:before {
  content: "\e046";
}
.icon-sort:before {
  content: "\e164";
}
.icon-sort_by_alpha:before {
  content: "\e053";
}
.icon-spa:before {
  content: "\eb4c";
}
.icon-space_bar:before {
  content: "\e256";
}
.icon-speaker:before {
  content: "\e32d";
}
.icon-speaker_group:before {
  content: "\e32e";
}
.icon-speaker_notes:before {
  content: "\ea18";
}
.icon-speaker_notes_off:before {
  content: "\ea19";
}
.icon-speaker_phone:before {
  content: "\e0d2";
}
.icon-spellcheck:before {
  content: "\ea1a";
}
.icon-star_border:before {
  content: "\e841";
}
.icon-star_half:before {
  content: "\e842";
}
.icon-stars:before {
  content: "\ea1b";
}
.icon-stay_primary_landscape:before {
  content: "\e0d5";
}
.icon-stop:before {
  content: "\e047";
}
.icon-stop_screen_share:before {
  content: "\e0e3";
}
.icon-storage:before {
  content: "\e1db";
}
.icon-store_mall_directory:before {
  content: "\e563";
}
.icon-straighten:before {
  content: "\e41c";
}
.icon-streetview:before {
  content: "\e56e";
}
.icon-strikethrough_s:before {
  content: "\e257";
}
.icon-style:before {
  content: "\e41d";
}
.icon-subdirectory_arrow_left:before {
  content: "\e5d9";
}
.icon-subdirectory_arrow_right:before {
  content: "\e5da";
}
.icon-subject:before {
  content: "\ea1c";
}
.icon-subscriptions:before {
  content: "\e064";
}
.icon-subtitles:before {
  content: "\e048";
}
.icon-subway:before {
  content: "\e56f";
}
.icon-supervisor_account:before {
  content: "\ea1d";
}
.icon-surround_sound:before {
  content: "\e049";
}
.icon-swap_calls:before {
  content: "\e0d7";
}
.icon-swap_horiz:before {
  content: "\ea1e";
}
.icon-swap_vert:before {
  content: "\ea1f";
}
.icon-swap_vertical_circle:before {
  content: "\ea20";
}
.icon-switch_camera:before {
  content: "\e41e";
}
.icon-switch_video:before {
  content: "\e41f";
}
.icon-sync_disabled:before {
  content: "\e628";
}
.icon-sync_problem:before {
  content: "\e629";
}
.icon-system_update:before {
  content: "\e62a";
}
.icon-system_update_alt:before {
  content: "\ea21";
}
.icon-tab2:before {
  content: "\ea22";
}
.icon-tab_unselected:before {
  content: "\ea23";
}
.icon-tablet:before {
  content: "\e32f";
}
.icon-tablet_android:before {
  content: "\e330";
}
.icon-tablet_mac:before {
  content: "\e331";
}
.icon-tap_and_play:before {
  content: "\e62b";
}
.icon-text_fields:before {
  content: "\e262";
}
.icon-text_format:before {
  content: "\e165";
}
.icon-texture:before {
  content: "\e421";
}
.icon-thumb_down:before {
  content: "\ea24";
}
.icon-thumb_up:before {
  content: "\ea25";
}
.icon-thumbs_up_down:before {
  content: "\ea26";
}
.icon-timelapse:before {
  content: "\e422";
}
.icon-timeline:before {
  content: "\ea27";
}
.icon-timer3:before {
  content: "\e425";
}
.icon-timer_10:before {
  content: "\e423";
}
.icon-timer_3:before {
  content: "\e424";
}
.icon-timer_off:before {
  content: "\e426";
}
.icon-title:before {
  content: "\e264";
}
.icon-toc:before {
  content: "\ea28";
}
.icon-today:before {
  content: "\ea29";
}
.icon-toll:before {
  content: "\ea2a";
}
.icon-tonality:before {
  content: "\e427";
}
.icon-touch_app:before {
  content: "\ea2b";
}
.icon-toys:before {
  content: "\e332";
}
.icon-track_changes:before {
  content: "\ea2c";
}
.icon-traffic:before {
  content: "\e565";
}
.icon-train:before {
  content: "\e570";
}
.icon-tram:before {
  content: "\e571";
}
.icon-transfer_within_a_station:before {
  content: "\e572";
}
.icon-transform:before {
  content: "\e428";
}
.icon-translate:before {
  content: "\ea2d";
}
.icon-trending_down:before {
  content: "\ea2e";
}
.icon-trending_flat:before {
  content: "\ea2f";
}
.icon-trending_up:before {
  content: "\ea30";
}
.icon-tune:before {
  content: "\e429";
}
.icon-tv:before {
  content: "\e333";
}
.icon-unarchive:before {
  content: "\e169";
}
.icon-undo3:before {
  content: "\e166";
}
.icon-unfold_less:before {
  content: "\e5d6";
}
.icon-unfold_more:before {
  content: "\e5d7";
}
.icon-update:before {
  content: "\ea31";
}
.icon-usb:before {
  content: "\e1e0";
}
.icon-verified_user:before {
  content: "\ea32";
}
.icon-vertical_align_bottom:before {
  content: "\e258";
}
.icon-vertical_align_center:before {
  content: "\e259";
}
.icon-vertical_align_top:before {
  content: "\e25a";
}
.icon-vibration:before {
  content: "\e62d";
}
.icon-video_call:before {
  content: "\e070";
}
.icon-video_label:before {
  content: "\e071";
}
.icon-video_library:before {
  content: "\e04a";
}
.icon-videocam:before {
  content: "\e04b";
}
.icon-videocam_off:before {
  content: "\e04c";
}
.icon-videogame_asset:before {
  content: "\e338";
}
.icon-view_agenda:before {
  content: "\ea33";
}
.icon-view_array:before {
  content: "\ea34";
}
.icon-view_carousel:before {
  content: "\ea35";
}
.icon-view_column:before {
  content: "\ea36";
}
.icon-view_comfy:before {
  content: "\e42a";
}
.icon-view_compact:before {
  content: "\e42b";
}
.icon-view_day:before {
  content: "\ea37";
}
.icon-view_headline:before {
  content: "\ea38";
}
.icon-view_list:before {
  content: "\ea39";
}
.icon-view_module:before {
  content: "\ea3a";
}
.icon-view_quilt:before {
  content: "\ea3b";
}
.icon-view_stream:before {
  content: "\ea3c";
}
.icon-view_week:before {
  content: "\ea3d";
}
.icon-vignette:before {
  content: "\e435";
}
.icon-visibility_off:before {
  content: "\ea3e";
}
.icon-voice_chat:before {
  content: "\e62e";
}
.icon-voicemail2:before {
  content: "\e0d9";
}
.icon-volume_down:before {
  content: "\e04d";
}
.icon-volume_mute:before {
  content: "\e04e";
}
.icon-volume_off:before {
  content: "\e04f";
}
.icon-volume_up:before {
  content: "\e050";
}
.icon-vpn_key:before {
  content: "\e0da";
}
.icon-vpn_lock:before {
  content: "\e62f";
}
.icon-wallpaper:before {
  content: "\e1bc";
}
.icon-watch2:before {
  content: "\e334";
}
.icon-watch_later:before {
  content: "\ea3f";
}
.icon-wb_auto:before {
  content: "\e42c";
}
.icon-wb_incandescent:before {
  content: "\e42e";
}
.icon-wb_iridescent:before {
  content: "\e436";
}
.icon-wb_sunny:before {
  content: "\e430";
}
.icon-wc:before {
  content: "\e63e";
}
.icon-web:before {
  content: "\e051";
}
.icon-web_asset:before {
  content: "\e069";
}
.icon-weekend:before {
  content: "\e16b";
}
.icon-whatshot:before {
  content: "\e821";
}
.icon-widgets:before {
  content: "\e1bd";
}
.icon-wifi2:before {
  content: "\e644";
}
.icon-wifi_lock:before {
  content: "\e1e1";
}
.icon-wifi_tethering:before {
  content: "\e1e2";
}
.icon-work:before {
  content: "\ea40";
}
.icon-wrap_text:before {
  content: "\e25b";
}
.icon-youtube_searched_for:before {
  content: "\ea41";
}
.icon-zoom_in:before {
  content: "\ea42";
}
.icon-zoom_out:before {
  content: "\ea43";
}
.icon-zoom_out_map:before {
  content: "\e56b";
}
.icon-biobug:before {
  content: "\ebd7";
}
.icon-dot:before {
  content: "\ebd8";
}
.icon-file-pdf:before {
  content: "\ebd9";
}

.icon-sort-by-arrows:before{
  content: "\21C5";
  color: #38b6d8;
}

.icon-sort-by-asc:before {
  content: "\2191";
  color: #38b6d8;
}

.icon-sort-by-desc:before {
  content: "\2193";
  color: #38b6d8;
}